import React, {useContext, useEffect, useState} from "react";
import '../../App.css';
import {API, Geo} from "aws-amplify";
import Form,{GroupItem, Item, RequiredRule, SimpleItem, ButtonItem, Label, EmptyItem} from "devextreme-react/form";
import FileUploader from "devextreme-react/file-uploader";
import {createObject} from "../../graphql/mutations";
import notify from "devextreme/ui/notify";
import {idgenerator} from "../../settings/functions";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {listObjects} from "../../graphql/queries";
import DataSource from "devextreme/data/data_source";
import ErrorPage from "../Pages/ErrorPage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CustomStore from "devextreme/data/custom_store";
import {customersStore} from "../../common/customersStore";
import {LoadPanel} from "devextreme-react/load-panel";
import {parseAlpha3ToInternalCountryCode, uploadToS3Bucket} from "../../common/helpers";
import Map from "../Map";
import {geoLocationStore} from "../../common/geoLocationStore";

const types = ['CITY','BUILDING','ZONE','ROOM'];

function ObjectCreateForm() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [objects, setObjects] = useState([]);
    const [formInputs, setFormInputs] = useState({
        name: '', // Object name
        type: null, // Object type
        description: '', // Object description
        street: '',
        zipcode: '',
        city: '',
        country: '',
        longitude: '', // Geo Position
        latitude: '', // Geo Position
        altitude: '', // Geo Position
        fotoToUpload: null, // File Object
        tag: '', // example: owner = Kunde
        parent: null, // parent ID, null otherwise
        parentID: null,
        creator: authContext.user.username,
        customer: (authContext?.accesslevel===1) ? null : authContext?.userInfoFromDB?.customer,
    });
    const [isLoading, setIsLoading] = useState(false);

    const renderBackgroundItem = () => {
        return (
            <div className="fileuploader-container">
                <FileUploader
                    id="file-uploader"
                    name={"background-uploader"}
                    selectButtonText={t('global.selectFotoBtnText')}
                    accept={"image/*"}
                    allowedFileExtensions={['.png', '.jpg', '.jpeg']}
                    multiple={false}
                    focusStateEnabled={false}
                    uploadMode="useForm"
                    showFileList={true}
                    onValueChanged={({value}) => setFormInputs({
                            ...formInputs,
                            fotoToUpload: value[0]
                        })
                    }
                />
            </div>
        )
    }

    const renderStreetMap = () => {
        return (
                <Map
                    longitude={formInputs.longitude}
                    latitude={formInputs.latitude}
                    title={formInputs.name}
                />
        )
    }

    // Fetches all City and Building Objects
    const fetchObjects = async () => {
        try {
            let types = [{type: {eq: 'BUILDING'}},{type: {eq: 'CITY'}}];
            if (formInputs.type) {
                if (formInputs.type === 'BUILDING') {
                    types = [{type: {eq: 'CITY'}}];
                } else if (formInputs.type === 'ROOM'  || formInputs.type === 'ZONE') {
                    types = [{type: {eq: 'BUILDING'}}];
                }
            }

            let filter =  {or: types}
            if (formInputs.customer) {
                filter = {and: [{or: types}, {customerID: {eq: formInputs.customer.id}}]}
            }
            let res = [];
            let nt = null;
            if (formInputs.type !== 'CITY') {
                do { // this loop is to overcome in a simple manner the limit first and then filter problem
                    const {data: {listObjects: {items: page, nextToken}}} = await API.graphql({
                        query: listObjects,
                        variables: {nextToken: nt, filter: filter},
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    });
                    nt = nextToken;
                    res = res.concat(page);
                } while (nt !== null)
            }
            setObjects(res);
        } catch (err) { console.error(err) }
    }

    // handles the onboard of the device
    const handleObjectCreate = async (e) => {
        e.preventDefault();
        let customer_formatted = formInputs.customer.bmonc_customer_name.replace(/ /g, "-");
        let groupWrite = [];
        let groupRead = [];
        if (authContext?.accesslevel === 1) { // if BMONC Master, use the selected groups
            groupWrite.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-USER")
        } else if (authContext?.accesslevel === 2) { // admin
            groupWrite.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-ADMIN");
            if (formInputs.customer.level === "CUSTOMER") {
                groupRead.push(customer_formatted+"-USER");
            } else {
                groupRead.push(customer_formatted+"-SUBCUSTOMERUSER");
            }
        } else {
            console.error("Error: Your current permissions do not allow this action");
        }

        let item = {
            name: formInputs.name,
            type: formInputs.type,
            description:  formInputs.description,
            street: formInputs.street,
            zipcode: formInputs.zipcode,
            city: formInputs.city,
            country: formInputs.country,
            longitude: formInputs.longitude, // Geo Position
            latitude: formInputs.latitude, // Geo Position
            altitude: formInputs.altitude, // Geo Position
            parentID: (formInputs.parent ? formInputs.parent : undefined), // parent ID, null otherwise
            creator: formInputs.username,
            customerID: formInputs.customer ? formInputs.customer.id : undefined,
            groupRead: groupRead,
            groupWrite: groupWrite
        }
        if (formInputs.fotoToUpload) {
            // upload background foto first
            const uploadDetails = await uploadToS3Bucket(formInputs.fotoToUpload);
            if (!uploadDetails) {
                notify("Foto konnte nicht hochgeladen werden.", "error", 3000);
                console.error("Error on upload to S3.");
                e.cancel = true;
                return;
            }
            item = {...item, ...uploadDetails};
        }

        return API.graphql({
            query: createObject,
            variables: {
                input: item,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(({data: {createObject: obj1}}) => {
            if (obj1.type === 'CITY') { // create also building and room
                return API.graphql({
                    query: createObject,
                    variables: {
                        input: {
                            ...item,
                            id: idgenerator(),
                            parentID: obj1.id,
                            name: `${obj1.name}_Building`,
                            type: 'BUILDING'
                        },
                    },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }).then(({data: {createObject: obj2}}) => {
                    return API.graphql({
                        query: createObject,
                        variables: {
                            input: {
                                ...item,
                                id: idgenerator(),
                                parentID: obj2.id,
                                name: `${obj2.name}_Room`,
                                type: 'ROOM'
                            },
                        },
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }).then(() => {
                        notify("Die Objekte wurden angelegt.", "success", 3000);
                        navigate(-1);
                    });
                });
            } else if (obj1.type === 'BUILDING') { // create also room
                return API.graphql({
                    query: createObject,
                    variables: {
                        input: {
                            ...item,
                            id: idgenerator(),
                            parentID: obj1.id,
                            name: `${obj1.name}_Room`,
                            type: 'ROOM'
                        },
                    },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }).then(() => {
                    notify("Die Objekte wurden angelegt.", "success", 3000);
                    navigate(-1);
                });
            } else {
                notify("Das Objekt wurde angelegt.", "success", 3000);
                navigate(-1);
            }
        }).catch((e) => {
            alert(JSON.stringify(e));
            console.error(e);
            e.cancel = true;
        });
    }

    // handle changes in general
    const handleChange = (event, name) => {
        if (!event?.event) {
            return null
        }
        if (!name) { // SelectBox or DateBox
            const {name, value} = event.event.target
            setFormInputs({
                ...formInputs,
                [name]: value
            });
        } else { // TextBox or similar
            setFormInputs({
                ...formInputs,
                [name]: event.value
            });
        }
    }

    // handle address changes
    const handleAddressChange = (event) => {
        setFormInputs({
            ...formInputs,
            ...event.value
        });
    }

    // handle Object type changes
    const handleTypeChange = (event, name) => {
        // check hierarchy in general
        let parentInfo = objects.find(element => element.id === formInputs.parent);
        let new_state = {
            [name]: event.value
        };
        if (parentInfo) {
            let parentIndex = types.indexOf(parentInfo.type)
            let typeIndex = name === 'parent' ? types.indexOf(formInputs.type) : types.indexOf(event.value)
            if (parentIndex >= typeIndex) {
                if (name === 'type') {
                    notify("An object of type " + event.value + " can not be added to a parent of type " + parentInfo.type, "warning", 3000);
                    new_state = {...new_state, parent: null, parentID: null};
                } else {
                    notify("An object of type " + formInputs.type + " can not be added to a parent of type " + parentInfo.type, "warning", 3000);
                    event.component.__ignoreEvent = true;
                    event.component.option("value", event.previousValue);
                    return false;
                }
            }
        }
        // check if type is City
        if (event.value==='CITY') {
            new_state = {...new_state, parent: null, parentID: null};
        }
        setFormInputs({...formInputs, ...new_state});
    }

    // handle Object customer changes
    const handleCustomerChange = (e) => {
        let sel_customer = e.value;

        // this prevents the selection of a not yet physically created customer in thingsboard
        if (!sel_customer || !sel_customer['tb_customerID']) {
            notify("Der Kunde hat noch keine Benutzer oder ist noch im DEMO Modus.", "warning", 3000);
            e.cancel = true;
            return;
        }

        setFormInputs({
            ...formInputs,
            customer: sel_customer,
            parent: undefined,
            parentID: undefined
        });
    }

    useEffect(() => {
        fetchObjects();
    }, [formInputs.customer, formInputs.type]);

    useEffect(() => {
        const searchText = `${formInputs.street}, ${formInputs.zipcode} ${formInputs.city}, ${formInputs.country}`;
        Geo.searchByText(searchText)
            .then(results => {
                if (results.length >= 1) {
                    const address = results[0];
                    const [longitude, latitude] = address.geometry.point;
                    setFormInputs({
                        ...formInputs,
                        longitude: longitude,
                        latitude: latitude,
                        street: formInputs.street ? formInputs.street : address.street,
                        zipcode: formInputs.zipcode ? formInputs.zipcode: address.postalCode,
                        city: formInputs.city ? formInputs.city: address.municipality,
                        country: formInputs.country ? formInputs.country: parseAlpha3ToInternalCountryCode(address.country),
                    })
                }
            });
    }, [formInputs.street, formInputs.zipcode, formInputs.city, formInputs.country]);

    if ((authContext?.authState === AuthState.SignedIn) &&
        (authContext?.accesslevel === 1 || authContext?.accesslevel === 2)) {
        return (
            <div id='create-object-form' className={"ObjectCreateForm"}>
                <Header />
                <div className={"Content"}>
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={{ of: '#create-object-form' }}
                        visible={isLoading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        hideOnOutsideClick={false}
                    />
                    <h2 className={"headline"}>{t("objectscreate.headline")}</h2>
                    <form action="create-object-action" onSubmit={(e) => {
                        setIsLoading(true);
                        handleObjectCreate(e).finally(() => setIsLoading(false))
                    }}>
                        <Form colCount={2} repaintChangesOnly>
                            <GroupItem caption={t("global.information")} colSpan={1}>
                                <Item dataField="name" colSpan={1} editorOptions={{
                                    value: formInputs.name,
                                    onValueChanged: handleChange
                                }}>
                                    <RequiredRule />
                                    <Label text={t("objectscreate.objectName")}/>
                                </Item>
                                <SimpleItem dataField={"type"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: types,
                                                onValueChanged: (e) => handleTypeChange(e, 'type'),
                                                searchEnabled: true,
                                            }}
                                >
                                    <RequiredRule/>
                                    <Label text={t("global.type")}/>
                                </SimpleItem>
                                <SimpleItem dataField={"description"} editorType="dxTextArea" editorOptions={{
                                    height: "125",
                                    value: formInputs.description,
                                    onValueChanged: handleChange
                                }}><Label text={t("global.description")}/></SimpleItem>
                                <SimpleItem dataField={"fotoToUpload"}
                                            render={renderBackgroundItem}
                                >
                                    <Label text={t("global.background-picture")}/>
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem caption={t("global.location")} colSpan={1}>
                                <SimpleItem dataField={"address"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: new CustomStore({...geoLocationStore}),
                                                onValueChanged: handleAddressChange,
                                                displayExpr: (data) => data ? `${data.street ? data.street+', ' : ''}${data.zipcode} ${data.city}, ${data.country}` : null,
                                                searchEnabled: true,
                                                placeholder: t("global.addressSearchPlaceholderText")
                                            }}
                                >
                                    <Label text={t("global.address")}/>
                                </SimpleItem>
                                <SimpleItem dataField={"streetmap"}
                                            render={renderStreetMap}
                                >
                                    <Label text={t("global.map")}/>
                                </SimpleItem>
                            </GroupItem>
                            { (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                                <GroupItem caption={t("global.customer")} colSpan={1}>
                                    <SimpleItem dataField={"customer"}
                                                editorType={'dxSelectBox'}
                                                editorOptions={{
                                                    dataSource: new CustomStore({...customersStore,
                                                        onLoading(loadOptions) {
                                                            loadOptions.sorting = authContext.accesslevel === 1 ? "bmonc_customer_name" : "name"
                                                        }
                                                    }),
                                                    onValueChanged: handleCustomerChange,
                                                    displayExpr: authContext?.accesslevel === 1 ? "bmonc_customer_name" : "name",
                                                    searchEnabled: true,
                                                }}
                                    >
                                        <RequiredRule message={"Customer is required"}/><Label text={t("global.customer")}/>
                                        <Label visible={false}/>
                                    </SimpleItem>
                                </GroupItem>
                                : null }
                            <GroupItem caption={t("global.relation")} colSpan={1}>
                                <SimpleItem dataField={"parent"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: new DataSource({
                                                    store: objects,
                                                    sort: "name"
                                                }),
                                                onValueChanged: (e) => handleChange(e, 'parent'),
                                                displayExpr: "name",
                                                valueExpr: "id",
                                                searchEnabled: true,
                                            }}
                                >
                                    {formInputs.type !== 'CITY' ? <RequiredRule /> : null }
                                    <Label visible={false}/>
                                </SimpleItem>
                            </GroupItem>
                            <EmptyItem/>
                            <GroupItem cssClass={"ButtonGroupItem"} colCount={2}>
                            <ButtonItem horizontalAlignment="right"
                                        buttonOptions={{
                                            text: t("objectscreate.button1"),
                                            type: 'success',
                                            useSubmitBehavior: true
                                        }}/>
                            <ButtonItem horizontalAlignment="right"
                                        cssClass="cancel-button"
                                        buttonOptions={{
                                            text: t("global.cancel"),
                                            type: 'cancel',
                                            onClick: () => navigate(-1)
                                        }}/>
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default ObjectCreateForm;