import React, {useContext, useState} from "react";
import '../../App.css';
import {idgenerator} from "../../settings/functions";
import Header from "../Header/Header";
import {API} from "aws-amplify";
import notify from "devextreme/ui/notify";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import Tile from "../Tile";
import {Popup} from "devextreme-react/popup";
import {RequiredRule, StringLengthRule} from "devextreme-react/validator";
import {Form, ButtonItem, Item, SimpleItem, Label, EmptyItem, PatternRule} from "devextreme-react/form";
import {createCustomer, updateUser} from "../../graphql/mutations";
import "devextreme-react/select-box";
import 'devextreme-react/check-box';
import {ScrollView} from "devextreme-react";
import ErrorPage from "../Pages/ErrorPage";
import {REGEXP_Phone} from "../../settings/validation";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function BecomeCustomer() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [popupVisible, setPopupVisible] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formInputs, setFormInputs] = useState( {
            phone:"",
            company:"",
            atu:"",
            website:"",
            address:"",
            iban:"",
            bic: "",
            zip:"",
            country:"",
        });

    // Use the submitted data to set the state
    const handleChange = (event, name) => {
        if (!name) {
            const {name, value} = event.event.target
            setFormInputs({ ...formInputs,
                [name]: value
            });
        } else { // TextBox or similar
            setFormInputs({ ...formInputs,
                [name]: event.value
            });
        }

    }

    const handleBecomeCustomer = async (e) => {
        e.preventDefault();
        const customerID = idgenerator();
        const timestamp = (new Date()).toISOString();
        return API.graphql({
            query: createCustomer,
            variables: {
                input: {
                    id: customerID,
                    name: (formInputs.company && formInputs.company !== '' ? formInputs.company : undefined ),
                    atu: formInputs.atu,
                    website: formInputs.website,
                    iban: formInputs.iban,
                    bic: formInputs.bic,
                    address: formInputs.address,
                    phone: formInputs.phone ? formInputs.phone : undefined,
                    email: authContext.userInfoFromDB.email,
                    zipcode: formInputs.zip,
                    country: formInputs.country,
                    level: 'CUSTOMER',
                    createdAt: timestamp,
                    updatedAt: timestamp
                },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then((e) => {
            API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: authContext.user.username,
                        status: 'REQUESTED',
                        customerID: customerID,
                        address: formInputs.address,
                        zipcode: formInputs.zip,
                        country: formInputs.country,
                        phone: formInputs.phone ? formInputs.phone : undefined,
                        updatedAt: timestamp
                    },
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            }).then((e) => {
                setSubmitted(true);
                setPopupVisible(false);
                authContext.userInfoFromDB.status = 'REQUESTED';
                notify("You requested to become customer.", "success", 3000);
                navigate("/");
            }).catch((e) => {
                console.error(e);
            });
        }).catch((e) => {
            console.error(e);
        });

    }

    const hideForm = () => setPopupVisible(false);

    // handles button click
    const showForm = () => {
        try {
            if (!submitted && authContext?.userInfoFromDB.role === 'DEMO' && authContext?.userInfoFromDB.status === 'CONFIRMED') {
                setPopupVisible(true);
            } else if (authContext?.userInfoFromDB.status === 'REQUESTED') {
                notify("Please wait until BMONC will approve your account", "warning", 3000);
            }
        } catch (err) {
            notify("The operation created an error.", "error", 3000);
            console.error(err);
        }
    }

    if (authContext?.authState === AuthState.SignedIn) {
        if (authContext?.accesslevel === -1) {
            return (
                <div className="BecomeCustomer">
                    <Header />
                    <div className={"Tiles"}>
                        <Tile onClick={() => showForm()} text={"Jetzt Benutzer werden"}/>
                        <Popup
                            visible={popupVisible}
                            onHiding={hideForm}
                            dragEnabled={false}
                            closeOnOutsideClick={false}
                            showCloseButton={true}
                            showTitle={true}
                            title="Benutzer werden"
                        >
                            <ScrollView width='100%' height='100%'>
                            <form action="become-user-action" onSubmit={handleBecomeCustomer}>
                                <Form colCount={1}>
                                    <Item dataField="phone" colSpan={1}
                                          editorOptions={{
                                              value: formInputs.phone,
                                              placeholder:"+4312345678912",
                                              onValueChanged: (e) => handleChange(e, 'phone')
                                          }}>
                                          <PatternRule message="Fehlerhafter Format" pattern={REGEXP_Phone} />
                                          <Label text={"Telefon"}/>
                                    </Item>
                                    <Item dataField="company" colSpan={1}
                                          editorOptions={{value: formInputs.company, onValueChanged: (e) => handleChange(e, 'company')}}>
                                        <Label text={"Firma"}/>
                                    </Item>
                                    <Item dataField="atu" colSpan={1}
                                          editorOptions={{value: formInputs.atu, onValueChanged: (e) => handleChange(e, 'atu')}}>
                                        <Label text={"ATU"}/>
                                    </Item>
                                    <Item dataField="website" colSpan={1}
                                          editorOptions={{value: formInputs.website, onValueChanged: (e) => handleChange(e, 'website')}}>
                                        <Label text={"Website/Domain"}/>
                                    </Item>
                                    <Item dataField="address" colSpan={1}
                                          editorOptions={{value: formInputs.address, onValueChanged: (e) => handleChange(e, 'address')}}>
                                        <RequiredRule message={"Address is required"}/>
                                        <Label text={"Adresse"}/>
                                    </Item>
                                    <Item dataField="iban" colSpan={1}
                                          editorOptions={{value: formInputs.iban, onValueChanged: (e) => handleChange(e, 'iban')}}>
                                        <StringLengthRule max={34}/>
                                        <Label text={"IBAN"}/>
                                    </Item>
                                    <Item dataField="bic" colSpan={1}
                                          editorOptions={{value: formInputs.bic, onValueChanged: (e) => handleChange(e, 'bic')}}>
                                        <StringLengthRule max={11}/>
                                        <Label text={"BIC"}/>
                                    </Item>
                                    <Item dataField="zip" colSpan={1}
                                          editorOptions={{value: formInputs.zip, onValueChanged: (e) => handleChange(e, 'zip')}}>
                                        <RequiredRule message={"Zip code is required"}/>
                                        <Label text={"PLZ"}/>
                                    </Item>
                                    <Item dataField="country"  editorType="dxSelectBox" colSpan={1}
                                          editorOptions={{
                                              items: t('countries:countries',{ returnObjects: true }),
                                              valueExpr:"val",
                                              displayExpr:"text",
                                              searchEnabled:true,
                                              onValueChanged: (e) => handleChange(e, 'country')}}>
                                        <RequiredRule message={"Country is required"}/>
                                        <Label text={"Land"}/>
                                    </Item>
                                    <EmptyItem />
                                    <SimpleItem
                                        editorType="dxCheckBox" colSpan={2}
                                        editorOptions={{
                                            text: t('agbdsgv.text')
                                        }}>
                                        <RequiredRule message={"This Checkbox is required"}/>
                                        <Label visible={false} />
                                    </SimpleItem>
                                    <EmptyItem />
                                    <ButtonItem horizontalAlignment="right"
                                                buttonOptions={{
                                                    text: 'Registrieren',
                                                    type: 'success',
                                                    useSubmitBehavior: true,
                                                }}
                                    />
                                </Form>
                            </form>
                            </ScrollView >
                        </Popup>
                    </div>
                </div>
            );
        }
    } else {
        return(<ErrorPage/>);
    }
}

export default BecomeCustomer;
