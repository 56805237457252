import React from "react";
import PropTypes from 'prop-types';
import { createMap, drawPoints } from 'maplibre-gl-js-amplify';
import 'maplibre-gl/dist/maplibre-gl.css';
import './Map.css';

function Map({longitude, latitude, title, zoom = 15}) {

    async function initializeMap() {
        const long = longitude ? parseFloat(longitude) : 0;
        const lat = latitude ? parseFloat(latitude) : 0;
        const map = await createMap({
            container: 'map', // An HTML Element or HTML element ID to render the map in https://maplibre.org/maplibre-gl-js/docs/API/classes/Map/
            center: [long, lat], // [Longitude, Latitude]
            zoom: zoom
        });
        map.on('load', function () {
            drawPoints(
                'mySourceName', // Arbitrary source name
                [
                    {
                        coordinates: [long, lat], // [Longitude, Latitude]
                        title: title ? title : null,
                        //address: ''
                    }
                ], // An array of coordinate data, an array of Feature data, or an array of [NamedLocations](https://github.com/aws-amplify/maplibre-gl-js-amplify/blob/main/src/types.ts#L8)
                map,
                {
                    showCluster: true,
                    unclusteredOptions: {
                        showMarkerPopup: true,
                        defaultColor: '#009ac2'
                    },
                    clusterOptions: {
                        showCount: true
                    }
                }
            );
        });
    }

    initializeMap();

    return(
        <div id={'map'} className={'Map'}/>
    );
}

Map.propTypes = {
    longitude: PropTypes.string.isRequired,
    latitude: PropTypes.string.isRequired,
    title: PropTypes.string,
    zoom: PropTypes.number
}

export default Map;