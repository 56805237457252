import React, { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { AuthState } from "@aws-amplify/ui-components";
import ErrorPage from "./ErrorPage";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import Tile from "../Tile";
import { API } from "aws-amplify";
import { getQuicksightEmbededUrlForRegisteredUser } from "../../graphql/queries";

const KachelApp = () => {
  const authContext = useContext(AuthContext);
  const [t] = useTranslation();
  if (authContext.authState !== AuthState.SignedIn) {
    return <ErrorPage />;
  }
  const onKachelClick = async () => {
    const response = await API.graphql({
      query: getQuicksightEmbededUrlForRegisteredUser,
      variables: { email: authContext.username },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log({ response });
    const {
      data: { getQuicksightEmbededUrlForRegisteredUser: item },
    } = response;
    window.open(item?.embedUrl, "_blank");
  };

  return (
    <div>
      <Header link={"/"} />
      <div className={"Tiles"}>
        {authContext.accesslevel === 1 && (
          <Tile onClick={onKachelClick} text={t("app.tileTitle")} />
        )}
      </div>
    </div>
  );
};

export default KachelApp;
