import React, {useContext} from "react";
import '../../App.css';
import Tile from "../Tile";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {useTranslation} from "react-i18next";
import {API} from 'aws-amplify';
import {getQuicksightEmbededUrlForRegisteredUser} from "../../graphql/queries";
import ErrorPage from "./ErrorPage";
import {useNavigate} from "react-router-dom";

function Reports() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();

    if (authContext.authState === AuthState.SignedIn) {
        return (
            <div className="Reports">
                <Header link={"/"}/>
                <div className={"Tiles"}>
                    { (authContext.accesslevel === 1) ?
                        <Tile onClick={() => navigate("/statistics")} text={t("reports.tiletitle1")}/>
                        : null }
                    { (authContext.accesslevel === 1) ?
                        <Tile onClick={async () => {
                            API.graphql({
                                query: getQuicksightEmbededUrlForRegisteredUser,
                                variables:{'email': authContext.username},
                                authMode: 'AMAZON_COGNITO_USER_POOLS'
                            }).then(data => {
                                const {data: {getQuicksightEmbededUrlForRegisteredUser: item}} = data;
                                window.open(item?.embedUrl, '_blank');
                            }).catch(error => {
                                console.error(error);
                            });
                        }} text={t("reports.tiletitle2")}/>
                        : null
                    }
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default Reports;
