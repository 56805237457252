import React, {useContext, useState, useEffect} from "react";
import '../../App.css';
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    Form,
    Pager,
    Paging,
    Popup,
    Lookup, SearchPanel,
    StateStoring,
} from "devextreme-react/data-grid";
import {NumericRule} from 'devextreme-react/validator';
import {Item, GroupItem} from "devextreme-react/form";
import {LoadPanel} from "devextreme-react";
import "devextreme-react/text-area";
import {API} from "aws-amplify";
import {deleteLoRaWANGateway, updateLoRaWANGateway} from "../../graphql/mutations";
import {
    getLoRaWANGateway
} from "../../graphql/queries";
import Header from "../Header/Header";
import {useTranslation} from "react-i18next";
import notify from "devextreme/ui/notify";
import {Item as TItem, Scrolling, Toolbar} from "devextreme-react/tree-list";
import ErrorPage from "../Pages/ErrorPage";
import CustomStore from "devextreme/data/custom_store";
import {listLoRaWANGatewaysMinimal, listObjectsMinimal} from "../../graphql/customQueries";
import {useNavigate} from "react-router-dom";

export const gatewaysStore = {
    key: 'id',
    load: async () => {
        let res = [];
        try {
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listLoRaWANGateways: {items: page, nextToken}}} = await API.graphql({
                    query: listLoRaWANGatewaysMinimal,
                    variables: {nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
        } catch (err) { console.error(err) }
        return {
            data: res
        };
    },
    update: (key, data) => {
        return API.graphql({
            query: updateLoRaWANGateway,
            variables:{input: {...data, id: key}},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            notify("Editieren erfolgreich", "success", 3000);
        }).catch((e) => {
            alert(JSON.stringify(e));
        });
    },
    remove: async (key) => {
        return API.graphql({
            query: deleteLoRaWANGateway,
            variables:{input: {id: key}},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            notify("Löschen erfolgreich", "success", 3000);
        }).catch((e) => {
            alert(JSON.stringify(e));
        });
    },
    byKey: (key) => {
        return API.graphql({
            query: getLoRaWANGateway,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getLoRaWANGateway: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No Gateway with Key ${key} found`, "error", 3000);
            });
    }
};

function GatewaysList() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [objects, setObjects] = useState([]);

    useEffect(() => {
        fetchObjects();
    }, []);


    const getFilteredObjects = (options) => {
        let filter = null;
        if (options.data != null) {
            filter = [["customerID", "=", options.data.customerID]];
        }
        return {
            store: objects,
            filter: filter
        };
    }

    // Fetches all Room and Zone Objects
    const fetchObjects = async () => {
        try {
            let filter =  {or: [ {type: {eq: 'ROOM'}}, {type: {eq: 'ZONE'}}]}
            let res = [];
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listObjects: {items: page, nextToken}}} = await API.graphql({
                    query: listObjectsMinimal,
                    variables: {nextToken: nt, filter: filter},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
            setObjects(res);
        } catch (err) { console.error(err) }
    }

    const renderGWStatus = (data) => {
        let color = 'red';
        if (data?.value === '1') {
            color = 'green';
        }
        return <div style={{ backgroundColor: color, borderRadius: '50%', width: '1rem',height: '1rem'}} />;
    }

    if (authContext?.authState === AuthState.SignedIn) {
        return (
            <div className="GatewaysList">
                <Header />
                <div className={"Content full-width"}>
                    <h2 className={"headline"}>{t("gatewayslist.headline")}</h2>
                    <DataGrid
                        dataSource={
                            new CustomStore(gatewaysStore)
                        }
                        remoteOperations={{
                            paging: false,
                            sorting: false,
                            filtering: false,
                        }}
                        keyExpr="id"
                        showBorders={false}
                        defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        rowAlternationEnabled={true}
                        allowColumnReordering={true}
                        wordWrapEnabled={true}
                    >
                        <Toolbar>
                            {(authContext?.accesslevel === 1) ?
                                <TItem
                                    name="addRowButton"
                                    location={"after"}
                                    locateInMenu={"auto"}
                                    onClick={() => navigate('/object-create')}
                                />
                                : null
                            }
                            <TItem
                                name="columnChooserButton"
                                locateInMenu="auto"
                                location="after"
                            />
                            <TItem name="searchPanel"  location={"after"} locateInMenu={"auto"}/>
                        </Toolbar>
                        { (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                            <Editing
                                mode={"popup"}
                                useIcons={true}
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                            >
                                <Popup title={t("gatewayslist.popup-title")} showTitle={true} height={"auto"} maxWidth={"1000px"} maxHeight={"100vh"} />
                                <Form colCount={2}>
                                    <GroupItem caption={t("global.information")} colSpan={1}>
                                        <Item dataField="name"/>
                                        <Item dataField="website"/>
                                        <Item dataField="comments" editorType="dxTextArea" editorOptions={{
                                            "height": "250px"
                                        }}/>
                                        <Item dataField="backhaulSimCard"/>
                                    </GroupItem>
                                    <GroupItem caption={t("global.location")} colSpan={1}>
                                        <Item dataField="objectID"/>
                                        <Item dataField="country"/>
                                        <Item dataField="longitude"><NumericRule/></Item>
                                        <Item dataField="latitude"><NumericRule/></Item>
                                        <Item dataField="altitude"><NumericRule/></Item>
                                    </GroupItem>
                                </Form>
                            </Editing> : null }
                        <ColumnChooser enabled={true} mode="select"/>
                        <StateStoring
                            enabled={true}
                            type="localStorage"
                            storageKey="gatewayslist"
                        />
                        <LoadPanel enabled/>
                        <Scrolling
                            mode="standard" />
                        <Paging
                            enabled={true}
                            defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 25, 50, 'all']}
                            showInfo={true}/>
                        <SearchPanel visible={true} width={"auto"}/>
                        <Column dataField="name" dataType="string" caption={t("global.name")} defaultSortIndex={0} defaultSortOrder="asc"/>
                        <Column dataField="connstate.code" dataType="string" caption={t("global.status")} cellRender={renderGWStatus}/>
                        <Column dataField="eui" dataType="string" caption={t("global.eui")}/>
                        <Column dataField="customer.name" dataType="string" caption={t("global.customer")}/>
                        <Column dataField="country" caption={t("global.country")}>
                            <Lookup dataSource={t('countries:countries',{ returnObjects: true })} valueExpr="val" displayExpr="text" searchEnabled={true}/>
                        </Column>
                        <Column dataField="website" dataType="string" caption={t("global.website")}/>
                        <Column dataField="backhaulSimCard" dataType="string" caption={t("global.backhaulsimcard")}/>
                        <Column dataField="comments" dataType="string" caption={t("global.comment")}/>
                        <Column dataField="longitude" dataType="string" caption={t("global.longitude")} visible={false}/>
                        <Column dataField="latitude" dataType="string" caption={t("global.latitude")} visible={false}/>
                        <Column dataField="altitude" dataType="string" caption={t("global.altitude")} visible={false}/>
                        <Column dataField="objectID" dataType="string" caption={t("global.relation")}>
                            <Lookup dataSource={getFilteredObjects} valueExpr="id" displayExpr="name"/>
                        </Column>
                    </DataGrid>
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default GatewaysList;
