/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://96n8gnvkk3.execute-api.eu-west-1.amazonaws.com/bmoncprod",
            "region": "eu-west-1"
        },
        {
            "name": "Sensor3GAPI",
            "endpoint": "https://vfd1ho8c1g.execute-api.eu-west-1.amazonaws.com/bmoncprod",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fjlighn7ejh65jp2zptow5sy6a.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:2c0c2775-7d50-4bc3-919a-8d930fd9c7ff",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_kstN7lBzI",
    "aws_user_pools_web_client_id": "p5isk6rcnvcfkren4p50betkm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bmoncprod-map-image-bucket-150424",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "search_indices": {
                "items": [
                    "PlaceIndex-bmoncprod"
                ],
                "default": "PlaceIndex-bmoncprod"
            },
            "maps": {
                "items": {
                    "StreetsMap-bmoncprod": {
                        "style": "VectorOpenDataStandardLight"
                    }
                },
                "default": "StreetsMap-bmoncprod"
            }
        }
    }
};


export default awsmobile;
