import {API} from "aws-amplify";
import notify from "devextreme/ui/notify";
import {
    getWirelessServiceProfiles,
    listWirelessServiceProfiles
} from "../graphql/queries";

export const wirelessServiceProfilesStore = {
    key: 'id',
    load: async (loadOptions) => {
        let wirelessServiceProfiles = [];
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data: {listWirelessServiceProfiles: {items: page, nextToken}}} = await API.graphql({
                query: listWirelessServiceProfiles,
                variable: {nextToken: nt},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            wirelessServiceProfiles = wirelessServiceProfiles.concat(page);
        } while (nt !== null)
        wirelessServiceProfiles.sort((a, b) => a?.name?.localeCompare(b?.name))
        return {
            data: wirelessServiceProfiles
        };
    },
    byKey: (key) => {
        return API.graphql({
            query: getWirelessServiceProfiles,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getWirelessServiceProfiles: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No Wireless Service Profile with Key ${key} found`, "error", 3000);
            });
    }
};