import React, {useContext} from "react";
import '../../App.css';
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import ErrorPage from "./ErrorPage";

function Support() {
    const authContext = useContext(AuthContext);

    return (authContext?.authState === AuthState.SignedIn ?
            <div className="Support">
                <Header link={"/"}/>
                <iframe className={"responsive-iframe"} title={"SupportFrame"} src="https://bmonc.eu/Kontakt/"/>
            </div>
           :
            <ErrorPage/>
    )
}

export default Support;
