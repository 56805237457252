import 'devextreme/dist/css/dx.light.css';
import '../../App.css';
import React, {useContext} from "react";
import DataGrid, {
    Column,
    Editing,
    Pager,
    Paging,
    Popup,
    FilterRow,
    ColumnChooser,
    Form, SearchPanel, StateStoring
} from 'devextreme-react/data-grid';
import {Item, RequiredRule, SimpleItem} from 'devextreme-react/form';
import {API} from "aws-amplify";
import {deleteDeviceType, updateDeviceType} from "../../graphql/mutations";
import {LoadPanel} from "devextreme-react";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {
    getDeviceType,
    listDeviceTypes
} from "../../graphql/queries";
import "devextreme-react/select-box";
import Header from "../Header/Header";
import {useTranslation} from "react-i18next";
import notify from "devextreme/ui/notify";
import {Scrolling} from "devextreme-react/tree-list";
import ErrorPage from "../Pages/ErrorPage";
import CustomStore from "devextreme/data/custom_store";

const renderDevTypeStatus = (data) => {
    let color = 'red';
    if (data.value === 'ACTIVE') {
        color = 'green';
    } else if (data.value === 'BLOCKED') {
        color = 'yellow';
    }
    return <div style={{ backgroundColor: color, borderRadius: '50%', width: '1rem',height: '1rem'}} />;
}

export const deviceTypesStore = {
    key: 'id',
    load: async () => {
        let res = [];
        try {
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listDeviceTypes: {items: page, nextToken}}} = await API.graphql({
                    query: listDeviceTypes,
                    variables: {nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
        } catch (err) { console.error(err) }
        return {
            data: res
        };
    },
    update: (key, data) => {
        API.graphql({
            query: updateDeviceType,
            variables:{input: {...data, id: key}},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            notify("Editieren erfolgreich", "success", 3000);
        }).catch((e) => {
            alert(JSON.stringify(e));
        });
    },
    remove: async (key) => {
        API.graphql({
            query: deleteDeviceType,
            variables:{input: {id: key}},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            notify("Löschen erfolgreich", "success", 3000);
        }).catch((e) => {
            alert(JSON.stringify(e));
        });
    },
    byKey: (key) => {
        return API.graphql({
            query: getDeviceType,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getDeviceType: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No Device Type with Key ${key} found`, "error", 3000);
            });
    }
};

function DeviceTypesList() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();

    if (authContext?.authState === AuthState.SignedIn) {
        return (
            <div className={"DevicesList"}>
                <Header />
                <div className={"Content full-width"}>
                    <h2 className={"headline"}>{ (authContext?.accesslevel === 1) ? t("devicetypeslist.headline1") : t("devicetypeslist.headline2")}</h2>
                    <DataGrid
                        dataSource={
                            new CustomStore(deviceTypesStore)
                        }
                        remoteOperations={{
                            paging: false,
                            sorting: false,
                            filtering: false,
                        }}
                        keyExpr="id"
                        showBorders={false}
                        defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        wordWrapEnabled={true}
                    >
                        { (authContext?.accesslevel === 1) ?
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowUpdating={true}
                            //allowAdding={true}
                            allowDeleting={true}
                        >
                            <Popup title={t("devicetypeslist.popup-title")} showTitle={true} height={"auto"} maxWidth={"600px"}>
                                <Form colCount={1}>
                                        <Item dataField="producer" colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="website" colSpan={1}/>
                                        <SimpleItem dataField={"status"} editorType="dxSelectBox" editorOptions={{
                                            items: ['PENDING', 'ACTIVE', 'BLOCKED'],
                                            searchEnabled: true
                                        }}>
                                            <RequiredRule/>
                                        </SimpleItem>
                                </Form>
                            </Popup>
                        </Editing> : null }
                        <ColumnChooser enabled={true} mode="select"/>
                        <StateStoring
                            enabled={true}
                            type="localStorage"
                            storageKey="devicestypeslist"
                        />
                        <LoadPanel enabled/>
                        <FilterRow/>
                        <SearchPanel visible={true} width={"auto"}/>
                        <Scrolling
                            mode="standard" />
                        <Paging
                            enabled={true}
                            defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 25, 50, 'all']}
                            showInfo={true}
                            infoText="Seite #{0}. Total: {1} ({2} Elemente)"/>
                        <FilterRow visible={true}/>
                        <Column dataField="name" dataType="string" caption={t("global.name")} defaultSortIndex={0} defaultSortOrder="asc"/>
                        <Column dataField="producer" dataType="string" caption={t("global.producer")}/>
                        <Column dataField="website" dataType="string" caption={t("global.website")}/>
                        <Column dataField="description" dataType="string" caption={t("global.description")}/>
                        {/*<Column dataField="wlDeviceProfileID" dataType="string"/>
                        <Column dataField="wlServiceProfileID" dataType="string"/>
                        <Column dataField="destinationName" dataType="string"/>*/}
                        <Column dataField="status" dataType="string" caption={t("global.status")} cellRender={renderDevTypeStatus}/>
                        {/*<Column dataField="details" dataType="string" caption="Details" defaultHidingPriority={0} cellRender={renderDevTypeWebsite}/>*/}
                    </DataGrid>
                </div>
            </div>
        );
    } else {
        return (<ErrorPage/>);
    }
}

export default DeviceTypesList;