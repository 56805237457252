import React, {useContext} from "react";
import '../../App.css';
import Tile from "../Tile";
import AuthContext from "../../contexts/AuthContext";
import Header from "../Header/Header";
import {AuthState} from "@aws-amplify/ui-components";
import {useTranslation} from "react-i18next";
import ErrorPage from "./ErrorPage";
import {useNavigate} from "react-router-dom";


function Sensors() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();

    if (authContext.authState === AuthState.SignedIn) {
        return (
            <div className="Sensors">
                <Header link={"/"}/>
                <div className={"Tiles"}>
                    <Tile onClick={() => navigate("/sensors-manage")} text={"Sensoren verwalten"}/>
                    {(authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                        <Tile onClick={() => navigate("/sensors-register")} text={"Sensor registrieren"}/>
                        :null
                    }
                    { (authContext?.accesslevel === 1) ?
                        <Tile onClick={() => navigate("/sensors-erstanlage")} text={"Sensor Erstanlage"}/>
                        :null
                    }
                    { (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                        <Tile onClick={() => navigate("/sensortypes-manage")} text={(authContext?.accesslevel === 1) ? "Sensor Typen verwalten" : "Sensor Typen anzeigen"}/>
                        :null
                    }
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default Sensors;
