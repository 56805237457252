import React, {useContext, useEffect, useState} from "react";
import '../../App.css';
import TreeList, {
    Button, HeaderFilter, SearchPanel, Editing, Toolbar, Item as TItem,
    Popup, Paging,
    Form, Column, Lookup, ColumnChooser, Sorting, Scrolling
} from "devextreme-react/tree-list";
import {
    Item, GroupItem, RequiredRule, Label, AsyncRule, PatternRule,
} from 'devextreme-react/form';
import {API} from "aws-amplify";
import {
    CompanybyBmoncCustomerName,
    CompanybyBmoncID,
    getCustomer,
    listCustomers,
    listUsers
} from "../../graphql/queries";
import {
    createCustomer,
    deleteCustomer,
    updateCustomer,
} from "../../graphql/mutations";
import {LoadPanel} from "devextreme-react/load-panel";
import {downloadCSVFile, idgenerator} from "../../settings/functions";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import notify from "devextreme/ui/notify";
import "devextreme-react/select-box";
import {SpeedDialAction} from "devextreme-react";
import SelectBox from "devextreme-react/select-box";
import {Pager, StateStoring} from "devextreme-react/data-grid";
import {MASK_Phone, REGEXP_Email, REGEXP_Phone} from "../../settings/validation";
import ErrorPage from "../Pages/ErrorPage";
import {useTranslation} from "react-i18next";

const editing_mode = "popup";
const filteringValues = [
    {
        value: [],
        text: 'Alle',
    },
    {
        value: [['status', '=', 'REQUESTED']],
        text: 'Offenen Kundenanfragen',
    }];

const onEditingStart = async (event) => {
    const bmonc_id_editable = !event.data.bmonc_id || event.data.bmonc_id==="" ? true:false;
    event.component.columnOption("createdAt", "formItem", {visible: true});
    event.component.columnOption("parent_customerID", "formItem", {visible: false});
    event.component.columnOption("bmonc_id", "allowEditing", bmonc_id_editable);
    event.component.columnOption("bmonc_customer_name", "allowEditing", bmonc_id_editable);
}

const onInitNewRow = (event) => {
    event.component.columnOption("createdAt", "formItem", {visible: false});
    event.component.columnOption("parent_customerID", "formItem", {visible: true});
    event.component.columnOption("bmonc_id", "allowEditing", true);
    event.component.columnOption("bmonc_customer_name", "allowEditing", true);
}

const checkBmoncIDAlreadyExists = async (params) => {
    const allowEditing = params?.formItem?.column?.allowEditing;
    if (!allowEditing) {
        return true;
    }
    let id = params.value;
    let not_exists= false;
    try {
        const {data : {CompanybyBmoncID: {items: page}}} = await API.graphql({
            query: CompanybyBmoncID,
            variables: {bmonc_id: id},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (page.length===0){
            not_exists = true;
        }
    } catch (err) { console.error(err) }
    return not_exists;
}

const checkBmoncNameAlreadyExists = async (params) => {
    const allowEditing = params?.formItem?.column?.allowEditing;
    if (!allowEditing) {
        return true;
    }
    let bmonc_customer_name = params.value;
    let not_exists= false;
    try {
        const {data : {CompanybyBmoncCustomerName: {items: page}}} = await API.graphql({
            query: CompanybyBmoncCustomerName,
            variables: {bmonc_customer_name: bmonc_customer_name},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (page.length===0){
            not_exists = true;
        }
    } catch (err) { console.error(err) }
    return not_exists;
}

const fetchCustomers = async () => {
    let customers = [];
    try {
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data : {listCustomers: {items: page, nextToken}}} = await API.graphql({
                query: listCustomers,
                variables: {nextToken: nt}, // filter: {or: [{type: {eq: 'ADMIN'}},{type: {eq: 'USER'}}]}
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            customers = customers.concat(page);
        } while (nt !== null)
    } catch (err) { console.error(err) }
    return customers;
}

// Fetches all Users
const fetchUsers = async () => {
    let users = [];
    try {
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data : {listUsers: {items: page, nextToken}}} = await API.graphql({
                query: listUsers,
                variables: {nextToken: nt, filter: {not: {role: {eq: 'MASTER'}}}},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            users = users.concat(page);
        } while (nt !== null)
    } catch (err) { console.error(err) }
    return users;
}

function CustomersList() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const [filtering, setFiltering] = useState([]);
    const [customers, setCustomers] = useState([]);


    const filterChanged = (e) => setFiltering(e.value);

    // Fetches all Customers

    const fetchCustomersAndUsers = async () => {
        let [users, customers] = await Promise.all([
            fetchUsers(),
            fetchCustomers()
        ]);
        // set the status on client side to filter for Customers with REQUESTED users
        for (let c of customers) {
            c.status = users.find(elem => (elem.customerID === c.id && elem.status === 'REQUESTED')) !== undefined ? "REQUESTED": "NOT_REQUESTED";
        }
        setCustomers(customers);
    }

    const getFilteredCustomers = (options) => {
        let filter = [["level", "=", options.data.level], 'and',['bmonc_customer_name', '<>', null]]
        return {
            store: customers,
            filter: filter,
            sort: "bmonc_customer_name"
        };
    }

    const addCustomer = async (event) => {
        //console.log(event);
        let additional_info = {
            id: idgenerator(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
        };
        additional_info['level'] = event.data.parent_customerID ? 'SUBCUSTOMER' : 'CUSTOMER'
        const bmonc_customer_name_formatted = event.data.bmonc_customer_name.replace(/ /g,'-');
        // important to avoid side effects
        if (event.data.parent_customerID === 0)
            delete event.data.parent_customerID;
        // set read and write permissions
        if (event.data.parent_customerID) {
            const {data : {getCustomer: customer}} = await API.graphql({
                query: getCustomer,
                variables: {id: event.data.parent_customerID},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            const parent_bmonc_customer_name_formatted = customer.bmonc_customer_name.replace(/ /g,'-');
            additional_info['groupRead'] = [bmonc_customer_name_formatted + "-SUBCUSTOMERUSER",
                bmonc_customer_name_formatted + "-ADMIN",
                parent_bmonc_customer_name_formatted + "-ADMIN"]
            additional_info['groupWrite'] = [bmonc_customer_name_formatted + "-ADMIN",
                parent_bmonc_customer_name_formatted + "-ADMIN"]
        } else {
            additional_info['groupRead'] = [bmonc_customer_name_formatted + "-USER",
                bmonc_customer_name_formatted + "-ADMIN"]
            additional_info['groupWrite'] = [bmonc_customer_name_formatted + "-ADMIN"]
        }
        const item = {...additional_info, ...event.data};
        API.graphql({query: createCustomer, variables: {input: item}}).then((e) => {
            notify("Neuer Kunde wurde angelegt"+ event.data.bmonc_id+ " " + event.data.bmonc_customer_name + " created", "success", 3000);
        }).catch((e) => {
            console.error(e);
            event.cancel = true;
        });
    }

    const editCustomer = async (event) => {
        let key = {id: event.key};
        let customer_data = event.newData;
        if (customer_data !== undefined) {
            API.graphql({query: updateCustomer, variables: {input: {...key, ...customer_data}}}).then((e) => {
                notify("Kunde " + event.key + " wurde aktualisiert", "success", 3000);
            }).catch((e) => {
                console.error(e);
                event.cancel = true;
            });
        }
    }

    const removeCustomer = (event) => {
        //console.log(event);
        if (authContext?.accesslevel !== 1) {
            alert("You do not have the rights for this action");
            event.cancel = true;
        } else {
            let key = {id: event.key};
            API.graphql({query: deleteCustomer, variables: {input: key}}).then((e) => {
                notify(event.key + " deleted", "success", 3000);
            }).catch((e) => {
                alert(e);
                event.cancel = true;
            });
        }
    }

    const customersToCSV = async () => {
        let order = ["bmonc_id", "bmonc_customer_name", "name", "atu", "email", "phone","address", "country", "zipcode","iban", "bic"];
        let names = ["BMONC-ID", "BMONC-Kundenname", "Name/Firma", "Atu", "E-mail", "Telefon","Adresse", "Land", "PLZ","IBAN", "BIC"];
        let parent_order = ["bmonc_id", "bmonc_customer_name"];
        let parent_names = ["Parent-ID", "Parent Name"];
        let separator = ";";
        try {
            let customersList = customers;
            let rows = [];
            rows.push(names.concat(parent_names).join(separator));
            // row wise
            customersList.forEach((row) => {
                // column wise
                let columns = [];
                order.forEach((col_name) => {
                    columns.push(row[col_name]);
                })
                if (row["parent_customer"]) {
                    let parent = row["parent_customer"];
                    parent_order.forEach((p) => {
                        columns.push(parent[p]);
                    })
                }
                rows.push(columns.join(separator));
            });
            downloadCSVFile(rows.join('\n'), "customerslist-"+(new Date().getTime())+".csv");
        } catch (err) { console.error(err) }
    }

    useEffect(() => {
        fetchCustomersAndUsers();
    }, []);

    if (authContext?.authState === AuthState.SignedIn) {
        if (authContext?.accesslevel === 1) {
            return (
                <div className="CustomersList">
                    <Header />
                    <div className={"Content full-width"}>
                        <h2 className={"headline"}>{t("customerslist.headline")}</h2>
                        <TreeList
                            dataSource={customers}
                            filterValue={filtering}
                            showBorders={true}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            wordWrapEnabled={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            keyExpr="id"
                            parentIdExpr="parent_customerID"
                            id="customers"
                            onRowInserting={addCustomer}
                            onRowUpdating={editCustomer}
                            onInitNewRow={onInitNewRow}
                            onEditingStart={onEditingStart}
                            onRowRemoving={removeCustomer}
                        >
                            <Sorting
                                mode="multiple" />
                            <Scrolling
                                mode="standard" />
                            <Paging
                                enabled={true}
                                defaultPageSize={10} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 25, 50, 'all']}
                                showInfo={true}/>
                            <ColumnChooser enabled={true} mode={"select"}/>
                            <StateStoring
                                enabled={true}
                                type="localStorage"
                                storageKey="customerslist"
                            />
                            <LoadPanel enabled/>
                            <SearchPanel visible={true} width={"auto"}/>
                            <HeaderFilter visible={true}/>
                            {(authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                                <Toolbar>
                                    <TItem location={"before"}  locateInMenu={"auto"}>
                                        <SelectBox
                                            width="225"
                                            items={filteringValues}
                                            displayExpr="text"
                                            valueExpr="value"
                                            value={filtering}
                                            onValueChanged={filterChanged} />
                                    </TItem>
                                    <TItem name="addRowButton" location={"after"} locateInMenu={"auto"}/>
                                    <TItem
                                        name="columnChooserButton"
                                        locateInMenu="auto"
                                        location="after"
                                    />
                                    <TItem name="searchPanel"  location={"after"} locateInMenu={"auto"}/>
                                </Toolbar>
                                : null
                            }
                            {(authContext?.accesslevel === 1) ?
                                <Editing
                                    mode={editing_mode}
                                    useIcons={true}
                                    allowAdding={true}
                                    allowUpdating={true}
                                    allowDeleting={false}>
                                    <Popup showTitle={false} height={"auto"} maxWidth={"1400px"} maxHeight={"100vh"}/>
                                    <Form colCount={2} showValidationSummary={true}>
                                        <GroupItem caption={t("global.customer_data")} colSpan={1}>
                                            <Item dataField="createdAt" colSpan={1}>
                                                <Label text={t("global.createdAt")}/>
                                            </Item>
                                            <Item dataField="name" colSpan={1}/>
                                            <Item dataField="atu" colSpan={1}/>
                                            <Item dataField="iban" colSpan={1}/>
                                            <Item dataField="bic" colSpan={1}/>
                                            <Item dataField="parent_customerID"/>
                                        </GroupItem>
                                        <GroupItem caption={t("global.contact")} colSpan={1}>
                                            <Item dataField="address" colSpan={1}/>
                                            <Item dataField="zipcode" colSpan={1}/>
                                            <Item dataField="country"/>
                                            <Item dataField="phone" editorType="dxTextBox" editorOptions={MASK_Phone} colSpan={1}>
                                                <PatternRule message="Fehlerhafter Format" pattern={REGEXP_Phone} />
                                            </Item>
                                            <Item dataField="email" colSpan={1}>
                                                <PatternRule message="Fehlerhafter Format" pattern={REGEXP_Email} />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption={t("global.bmonc_intern")} colSpan={1}>
                                            <Item dataField="bmonc_id" colSpan={1}>
                                                <RequiredRule/>
                                                <AsyncRule message="This BMONC ID is already used" validationCallback={checkBmoncIDAlreadyExists}/>
                                            </Item>
                                            <Item dataField="bmonc_customer_name" colSpan={1}>
                                                <RequiredRule/>
                                                <AsyncRule message="This BMONC internal customer name is already used" validationCallback={checkBmoncNameAlreadyExists}/>
                                            </Item>
                                        </GroupItem>
                                    </Form>
                                </Editing> : null}
                            {(authContext?.accesslevel === 1) ?
                                <SpeedDialAction
                                icon="file"
                                label={t("customerslist.button1")}
                                index={2}
                                elementAttr={{class: "csvExportButton"}}
                                onClick={() => {
                                    customersToCSV();
                                }}/>
                                :
                                null
                            }
                            <Column dataField="bmonc_id" caption={t("global.bmonc_id")} defaultSortOrder="asc"/>
                            <Column dataField="bmonc_customer_name" caption={t("global.bmonc_customer_name")}/>
                            <Column dataField="name" caption={t("global.company-name")} />
                            <Column dataField="country" caption={t("global.country")}>
                                <Lookup dataSource={t('countries:countries',{ returnObjects: true })} valueExpr="val" displayExpr="text" searchEnabled={true}/>
                            </Column>
                            <Column dataField="zipcode" caption={t("global.zipcode")}/>
                            <Column dataField="address" caption={t("global.address")}/>
                            <Column dataField="email" caption={t("global.email")}/>
                            <Column dataField={"parent_customerID"} caption={t("global.relation")} visible={false}>
                                <Lookup dataSource={getFilteredCustomers({data:{level:'CUSTOMER'}})} valueExpr="id" displayExpr="bmonc_customer_name" searchEnabled={true}/>
                            </Column>
                            <Column dataField="atu" caption={t("global.atu")} visible={false}/>
                            <Column dataField="phone" caption={t("global.phone")} visible={false}/>
                            <Column dataField="iban" caption={t("global.iban")} visible={false}/>
                            <Column dataField="bic" caption={t("global.bic")} visible={false}/>
                            <Column dataField="status" caption={t("global.status")} visible={false}/>
                            <Column dataField="createdAt" allowEditing={false} caption={t("global.creationdate")} dataType="datetime" />
                            <Column type="buttons">
                                <Button name="edit" />
                                <Button name="delete" />
                            </Column>
                        </TreeList>
                    </div>
                </div>
            );
        }
    } else {
        return(<ErrorPage/>);
    }
}

export default CustomersList;
