import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import countriesEN from './locales/en/countries.json';
import translationDE from './locales/de/translation.json';
import countriesDE from './locales/de/countries.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
        countries: countriesEN,
    },
    de: {
        translation: translationDE,
        countries: countriesDE,
    }
};

i18n
    //.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        language: "de",
        fallbackLng: "de", // use en if detected lng is not available
        //debug: true,
        defaultNS: 'translation',
        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
        }
    });

export default i18n;