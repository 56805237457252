import {API} from "aws-amplify";
import notify from "devextreme/ui/notify";
import {
    DeviceTypesByStatus,
    getDeviceType
} from "../graphql/queries";

export const deviceTypeStore = {
    key: 'id',
    load: async (loadOptions) => {
        let deviceTypes = [];
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data: {DeviceTypesByStatus: {items: page, nextToken}}} = await API.graphql({
                query: DeviceTypesByStatus,
                variables: {
                    status: "ACTIVE",
                    limit: 100,
                    nextToken: nt},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            deviceTypes = deviceTypes.concat(page);
        } while (nt !== null)
        deviceTypes.sort((a, b) => a?.name?.localeCompare(b?.name))
        return {
            data: deviceTypes
        };
    },
    byKey: (key) => {
        return API.graphql({
            query: getDeviceType,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getDeviceType: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No DeviceType with Key ${key} found`, "error", 3000);
            });
    }
};