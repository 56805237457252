import {demo_group_name} from "./settings";

// returns the accesslevel
// 1 ... BMONCMASTER, 2 ... ADMIN, 3 ... USER, 4 ... SUBCUSTOMERUSER, -1 ... DEMO USER
// param groupslist ... list of groups to which the user belongs
export function getAccessLevel(groupslist) {
    let accesslevel = -1;
    if (groupslist === undefined) {
        return -1;
    }
    const adminGroups = groupslist.filter(g => g.endsWith("-ADMIN"));
    const userGroups = groupslist.filter(g => g.endsWith("-USER"));
    const subcustomeruserGroups = groupslist.filter(g => g.endsWith("-SUBCUSTOMERUSER"));
    if (groupslist.includes("BMONCMaster")) {
        accesslevel = 1;
    } else if (groupslist.includes(demo_group_name)) {
        accesslevel = -1;
    } else if (adminGroups.length>0){
        accesslevel = 2;
    } else if (userGroups.length>0){
        accesslevel = 3;
    } else if (subcustomeruserGroups.length>0){
        accesslevel = 4;
    }
    return accesslevel;
}

export function idgenerator() {
    const { v4: uuidv4 } = require('uuid');
    return uuidv4();
};

export function downloadCSVFile(csv_data, filename) {
    // Create CSV file object and feed our
    // csv_data into it
    let CSVFile = new Blob([csv_data], { type: "text/csv" });

    // Create to temporary link to initiate
    // download process
    let temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = filename;
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
}