import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Button as TextBoxButton, TextBox} from "devextreme-react/text-box";
import { ValidationGroup } from 'devextreme-react/validation-group';

function SaveChangesTextBox(props) {

    const [value, setValue] = useState(props.value ? props.value : "");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const saveButton = {
        icon: 'save',
        type: 'default',
        visible: !isButtonDisabled,
        onClick:  (e) => {
            let result = e.validationGroup.validate();
            if (result.isValid) {
                props.onClick(props.buttonName, value);
                setIsButtonDisabled(true);
            }
        }
    };

    return(
        <ValidationGroup>
            <TextBox
                name={props.buttonName+"tbox"}
                placeholder={props.placeholder ? props.placeholder : ""}
                readOnly={props.readOnly ? props.readOnly : false}
                value={value}
                onValueChanged={(event) => setValue(event.value)}
                onKeyUp={() => setIsButtonDisabled(false)}>
                    <TextBoxButton
                        name={props.buttonName}
                        location="after"
                        options={saveButton}
                    />
                {props.children}
            </TextBox>
        </ValidationGroup>
    );
}

SaveChangesTextBox.propTypes = {
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    buttonName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

export default SaveChangesTextBox;