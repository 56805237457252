import React, {useContext, useState, useEffect} from "react";
import '../../App.css';
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {useTranslation} from "react-i18next";
import {API, Auth} from "aws-amplify";
import {getDevices} from "../../graphql/queries";
import Form, {AsyncRule, ButtonItem, EmptyItem, GroupItem, Item, Label, RequiredRule} from "devextreme-react/form";
import ErrorPage from "../Pages/ErrorPage";
import awsconfig from '../../aws-exports';
import { SFNClient, StartExecutionCommand } from "@aws-sdk/client-sfn";
import notify from "devextreme/ui/notify";
import {useNavigate, useParams} from "react-router-dom";

const swapStateMachineArn = process.env.REACT_APP_DEVICE_SWAP_STATE_MACHINE_ARN;

const serialNrValidation = async (params) => {
    const serialNr = params.value;
    let not_exists= false;
    try {
        const {data : {DevicesBySerialNr: {items: page}}} = await API.graphql({
            query: "query DevicesBySerialNr {DevicesBySerialNr(serialNr: \""+serialNr+"\") {items {id}}}",
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (page.length===0){
            not_exists = true;
        }
    } catch (err) { console.error(err) }
    return not_exists;
}

function Sensor3gSwap() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [formInputs, setFormInputs] = useState(null);

    const startSwapExecution = async (inputStr, name) => {
        const sfn_client = new SFNClient({region: awsconfig.aws_project_region, credentials: await Auth.currentCredentials()});
        const input = { // StartExecutionInput
            stateMachineArn: swapStateMachineArn, // required
            name: name,
            input: inputStr
        };
        return sfn_client.send(new StartExecutionCommand(input));
    }

    // Use the submitted data to set the state
    const handleChange = (event, name) => {
        if (!event.event) return
        if (!name) { // SelectBox or DateBox
            const {name, value} = event.event.target
            setFormInputs({ ...formInputs,
                [name]: value
            });
        } else { // TextBox or similar
            setFormInputs({ ...formInputs,
                [name]: event.value
            });
        }
    }

    const fetchDevice = async () => {
        try {
            const {data: {getDevices: device}} = await API.graphql({
                    query: getDevices,
                    variables: {id: id},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
            console.log(device)
            setFormInputs({...formInputs, ...device});
        } catch (err) {
            console.error(err);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const newSerialNr = formInputs?.swap3gSensorData?.newSerialNr;
        const measImportStartDateTime = new Date(formInputs?.swap3gSensorData?.measImportStartDateTime).toISOString();
        const measImportEndDateTime = new Date(formInputs?.swap3gSensorData?.measImportEndDateTime).toISOString();
        const startTimeStamp = new Date(formInputs?.swap3gSensorData?.measImportStartDateTime).getTime();
        const timeStamp = new Date().toISOString();
        if (measImportStartDateTime < measImportEndDateTime) {
            notify("Das Bis-Datum muss vor dem Ab-Datum liegen.", "error", 3000);
            return;
        }
        const inputs = {
            id: formInputs.id,
            serialNr: formInputs.serialNr,
            swapDate: measImportStartDateTime,
            newSerialNr: newSerialNr,
            timeStamp: timeStamp,
            swap3gSensorData: {
                newSerialNr: newSerialNr,
                measImportEndDateTime: measImportEndDateTime,
                measImportStartDateTime: measImportStartDateTime
            }
        }
        return startSwapExecution(JSON.stringify(inputs), `swap-${newSerialNr}-${startTimeStamp}`)
            .then(() => {
                navigate("/sensors-manage");
                notify("Sensor Tausch wurde erfolgreich geplant.", "success", 3000)
            })
            .catch((err) => {
                console.error(err);
                notify("Fehler bei Sensor Tausch Planung.", "error", 3000);
            });
    };

    useEffect(() => {
        fetchDevice();
    }, [id]);

    return (authContext?.authState === AuthState.SignedIn ?
            <div className="Sensor3gSwap">
                <Header />
                <div className={"Content full-width"}>
                    <h2 className={"headline"}>{"3G Sensor Tausch"}</h2>
                    <form action="sensor-3g-swap" onSubmit={handleSubmit}>
                    <Form formData={formInputs}
                          colCount={2}
                          showValidationSummary={true}
                    >
                        <GroupItem caption={t("global.information")} colSpan={1}>
                            <Item dataField="name" editorOptions={{readOnly:true}}>
                                <Label text={t("global.name")}/>
                                <RequiredRule />
                            </Item>
                            <Item dataField="serialNr" editorOptions={{readOnly:true}}>
                                <Label text={t("global.serialNr")}/>
                                <RequiredRule />
                            </Item>
                            <Item dataField="swap3gSensorData.newSerialNr">
                                <Label text="neue Seriennr."/>
                                <RequiredRule />
                                <AsyncRule message={t("devicescreate.duplicateSerialNr")}
                                           validationCallback={serialNrValidation}/>
                            </Item>
                            <Item dataField="swap3gSensorData.measImportEndDateTime"
                                  editorType="dxDateBox"
                                  editorOptions={{
                                      type: 'datetime',
                                      onValueChanged: (e) => handleChange(e, "measImportEndDateTime")
                                  }}
                            >
                                <Label text={t("global.oldDeviceMeasImportEndDateTime")}/>
                                <RequiredRule />
                            </Item>
                            <Item dataField="swap3gSensorData.measImportStartDateTime"
                                  editorType="dxDateBox"
                                  editorOptions={{
                                      type: 'datetime',
                                      onValueChanged: (e) => handleChange(e, "measImportStartDateTime")
                                  }}>
                                <Label text={t("global.newDeviceMeasImportStartDateTime")}/>
                                <RequiredRule />
                            </Item>
                        </GroupItem>
                        <GroupItem caption={t("global.location")} colSpan={1}>
                            <Item dataField="object.name" editorOptions={{readOnly:true}}><Label text={t("global.name")}/></Item>
                            <Item dataField="longitude" editorOptions={{readOnly:true}}><Label text={t("global.longitude")}/></Item>
                            <Item dataField="latitude" editorOptions={{readOnly:true}}><Label text={t("global.latitude")}/></Item>
                            <Item dataField="altitude" editorOptions={{readOnly:true}}><Label text={t("global.altitude")}/></Item>
                        </GroupItem>
                        <EmptyItem/>
                        <GroupItem cssClass={"ButtonGroupItem"} colCount={2}>
                            <ButtonItem horizontalAlignment="right"
                                        buttonOptions={{
                                            text: formInputs?.swapProcessStatus === "SCHEDULED" ? "Tausch geplant" : "Tausch planen",
                                            type: 'success',
                                            disabled: formInputs?.swapProcessStatus === "SCHEDULED",
                                            useSubmitBehavior: true
                                        }}/>
                            <ButtonItem horizontalAlignment="right"
                                        cssClass="cancel-button"
                                        buttonOptions={{
                                            text: t("global.cancel"),
                                            type: 'cancel',
                                            onClick: () => {
                                                navigate("/sensors-manage");
                                            }
                                        }}/>
                        </GroupItem>
                    </Form>
                    </form>
                </div>
            </div>
            :
            <ErrorPage/>
    )
}

export default Sensor3gSwap;
