import React, {useContext, useEffect, useState} from "react";
import {API} from "aws-amplify";
import '../../App.css';
import Tile from "../Tile";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {useTranslation} from "react-i18next";
import notify from "devextreme/ui/notify";
import {getTBToken} from "../../graphql/queries";
import ErrorPage from "./ErrorPage";
import {useNavigate} from "react-router-dom";

const dashboard_url = process.env.REACT_APP_THINGSBOARD_URL ? process.env.REACT_APP_THINGSBOARD_URL : "https://dashboard.bmonc.eu/";

function Landingpage() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [tb_tokens, setTBTokens] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //const jwtToken = authContext.user.getSignInUserSession().getIdToken().getJwtToken();
        const tb_userID = authContext.userInfoFromDB.tb_userID;
        API.graphql({
            query: getTBToken,
            variables:{'userId': tb_userID},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(data => {
            const {data: {getTBToken: tokens}} = data;
            setTBTokens(tokens);
        }).catch(error => {
            console.error(error);
        }).finally(() => setLoading(false));
    }, []);

    if (authContext.authState === AuthState.SignedIn) {
            return (
                <div className="Landingpage">
                    <Header showSignOutButton={true}/>
                    <div className={"Tiles"}>
                        { (authContext.accesslevel === 1 || authContext.accesslevel === 2) ?
                            <Tile onClick={() => navigate(authContext.accesslevel === 1 ? "/customers" : "/users-list")}
                                  text={authContext.accesslevel === 1 ? t("landingpage.tiletitle1a"): t("landingpage.tiletitle1b")}/>
                            : null
                        }
                        <Tile onClick={() => navigate("/sensors")} text={t("landingpage.tiletitle2")}/>
                        <Tile onClick={() => navigate("/gateways-list")} text={t("landingpage.tiletitle3")}/>
                        <Tile onClick={() => navigate("/object-list")} text={t("landingpage.tiletitle4")}/>
                        { (authContext.accesslevel === 1) ?
                            <Tile onClick={() => navigate("/reports")} text={t("landingpage.tiletitle5")}/>
                            : null
                        }
                        { (authContext.accesslevel === 1) ?
                            <Tile onClick={() => navigate("/statuses")} text={t("landingpage.tiletitle6")}/>
                            : null
                        }
                        {tb_tokens ?
                            <Tile
                                href={`${dashboard_url}?accessToken=${tb_tokens?.token}&refreshToken=${tb_tokens?.refreshToken}`}
                                text={t("landingpage.tiletitle7")}
                                loading={loading}
                            />
                            :
                            <Tile onClick={() => notify("Lädt gerade Tokens. Bitte versuchen Sie es gleich nocheinmal.", "success", 3000)}
                                  text={t("landingpage.tiletitle7")}
                            />
                        }
                        { (authContext.accesslevel === -1 && authContext.userInfoFromDB.status !== 'REQUESTED') ?
                            <Tile onClick={() => navigate("/become-customer")}
                                  text={t("landingpage.tiletitle8")}
                            />
                            : null
                        }
                        <Tile onClick={() => navigate("/support")} text={t("landingpage.tiletitle9")}/>
                        <Tile onClick={() => navigate("/news")} text={t("landingpage.tiletitle10")}/>
                        <Tile onClick={() => navigate("/app")} text={t("landingpage.tiletitle11")}/>
                    </div>
                </div>
            );
        } else {
            return(<ErrorPage/>);
        }
}

export default Landingpage;
