import {Storage} from "aws-amplify";

const region = "eu-west-1";
const fotoPrefix = "images/objects/"

export const uploadToS3Bucket = async (file) => {
    const fullFileName = file.name;
    const dotIndex = fullFileName.lastIndexOf('.');
    const filename = fullFileName.slice(0,dotIndex).replace(/[. *+?^${}()|[\]\\]/g, "-");
    const extension = fullFileName.slice(dotIndex);
    const timestamp = new Date().getTime();
    const destFile = `${fotoPrefix}${filename}-${timestamp}${extension}`;

    return Storage.put(destFile, file, {
            bucket: process.env.REACT_APP_S3_FOTO_BUCKET_NAME,
            region: region,
            level: "protected",
            /*progressCallback(progress) {
                //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                progressCallback(progress.loaded);
            }*/
        }
    ).then(({key}) => {
        return Storage.get(key, {
                bucket: process.env.REACT_APP_S3_FOTO_BUCKET_NAME,
                region: region,
                level: "protected"
            }
        ).then((link) => {
            const cleanedUrl = link.split('?')[0];
            return {
                foto: {
                    bucket: process.env.REACT_APP_S3_FOTO_BUCKET_NAME,
                    region: region,
                    key: key
                },
                background: cleanedUrl
            }
        }).catch((e) => {
            console.error(e);
            return null;
        });
    }).catch((e) => {
        console.error(e);
        return null;
    });
}

export const getFotoS3Link = async (key) => {
    if (key) {
        return Storage.get(key, {
                bucket: process.env.REACT_APP_S3_FOTO_BUCKET_NAME,
                region: region,
                level: "protected"
            }
        ).then((link) => {
            return link;
        }).catch((e) => {
            console.error(e);
        });
    }
    return null;
}

export const startPolling = (callApiFn, testFn, time) => {
    let polling = false;
    let rejectThis = null;

    const stopPolling = () => {
        if (polling) {
            console.log(new Date(), "Polling was already stopped...");
        } else {
            console.log(new Date(), "Stopping polling...");
            polling = false;
            rejectThis(new Error("Polling cancelled"));
        }
    };

    const promise = new Promise((resolve, reject) => {
        polling = true;
        rejectThis = reject;

        const executePoll = async () => {
            try {
                const result = await callApiFn();
                if (polling && testFn(result)) {
                    polling = false;
                    resolve(result);
                } else {
                    setTimeout(executePoll, time);
                }
            } catch (error) {
                polling = false;
                reject(new Error("Polling cancelled due to API error"));
            }
        };

        setTimeout(executePoll, time);
    });

    return { promise, stopPolling };
}

export const timeout = (time) => new Promise((resolve) => setTimeout(resolve, time));

export const parseAlpha3ToInternalCountryCode = (a3code) => {
    if (a3code === 'AUT') {
        return 'AT'
    } else if (a3code === 'DEU') {
        return 'DE'
    } else {
        return a3code
    }
}

export const applySelectedFilteringForAPI = (filterOps, negation = false) => {
    if (filterOps !== null && filterOps?.length > 0) {
        const firstElement = filterOps.shift();
        if (filterOps[0] === 'or' || filterOps[0] === 'and') {
            // or/and operations
            return {[filterOps[0]]: [
                    applySelectedFilteringForAPI(firstElement, negation),
                    applySelectedFilteringForAPI(filterOps.slice(1), negation),
                ]};
        }
        else if (firstElement === 'or' || firstElement === 'and') {
            // or/and operations
            return {[firstElement]: [
                    applySelectedFilteringForAPI( filterOps.slice(0), negation),
                ]};
        } else if (Array.isArray(firstElement)) {
            // to unwrap array in array
            return applySelectedFilteringForAPI(firstElement, negation);
        } else if (firstElement === '!') {
            // not operations
            return applySelectedFilteringForAPI(filterOps, true);
        } else if (firstElement === 'id') {
            // basic operation
            if (negation === true) {
                return {[firstElement]: {'ne': filterOps[1]}}
            } else {
                return {[firstElement]: {'eq': filterOps[1]}}
            }
        } else if (firstElement === '=') {
            // basic operation
            if (negation === true) {
                return {'id': {'ne': filterOps[0]}}
            } else {
                return {'id': {'eq': filterOps[0]}}
            }
        }
    }
};