import React, {useState} from "react";
import {Button} from "devextreme-react/button";
import {useTranslation} from "react-i18next";

function BackgroundImgFormItem({data}) {
    const [t] = useTranslation();
    const [url, setUrl] = useState(data.value);
    return (
        url ?
            <>
                <img src={url}
                     alt={'background-image'}
                     style={{width: "100%", maxWidth: "100%"}}
                     loading="lazy"
                />
                <Button
                    type={"normal"}
                    icon="trash"
                    stylingMode={"contained"}
                    onClick={() => {setUrl(null); data.setValue(null);}}
                />
            </>
            :
            <>{t('global.noImageText')}</>
    )
}

export default BackgroundImgFormItem;