import React, {useContext} from "react";
import '../../App.css';
import TreeList, {
    HeaderFilter, Editing,
    Popup, Column,
    Form, Scrolling, Lookup, Toolbar, Item as TItem
} from 'devextreme-react/tree-list';
import {
    Item, GroupItem, SimpleItem, Label, RequiredRule
} from 'devextreme-react/form';
import {API} from "aws-amplify";
import {getObject} from "../../graphql/queries";
import {createObject, deleteObject, updateObject} from "../../graphql/mutations";
import {LoadPanel} from "devextreme-react";
import notify from 'devextreme/ui/notify';
import {ColumnChooser, Pager, Paging, SearchPanel, StateStoring} from "devextreme-react/data-grid";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {NumericRule} from "devextreme-react/validator";
import {useTranslation} from "react-i18next";
import ErrorPage from "../Pages/ErrorPage";
import CustomStore from "devextreme/data/custom_store";
import FileUploader from "devextreme-react/file-uploader";
import {
    uploadToS3Bucket
} from "../../common/helpers";
import {customersStore} from "../../common/customersStore";
import BackgroundImgFormItem from "../BackgroundImgFormItem";
import {geoLocationStore} from "../../common/geoLocationStore";
import {listObjectsMinimal} from "../../graphql/customQueries";
import {useNavigate} from "react-router-dom";

const editing_mode = "popup";
const types = ['CITY','BUILDING','ZONE','ROOM'];

// Fetches all ChildNames
const fetchChildObjects = async (parentID) => {
    try {
        const {data : {objectByParentID: {items: page}}} = await API.graphql({
            query: "query MyQuery {objectByParentID(parentID: \""+parentID+"\") {items {id}}}",
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        return page;
    } catch (err) { console.error(err) }
}

const getFilteredTypes = (options) => {
    const parentType = options?.data?.parentType;
    let filteredTypes = types;
    if (parentType === 'CITY') {
        filteredTypes = ['BUILDING']
    } else if (parentType === 'BUILDING') {
        filteredTypes = ['ZONE', 'ROOM']
    }
    return {
        store: filteredTypes
    };
}

export const objectsStore = {
    key: 'id',
    load: async (options) => {
        const {parentIds} = options;
        let res = [];
        //const applyFilter = applySelectedFilteringForAPI(filter, false);
        try {
            let filterExpr = {or: parentIds.map(parentID => parentID ? {parentID: {eq: parentID}} : {parentID: {attributeExists: false}})}
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listObjects: {items: page, nextToken}}} = await API.graphql({
                    query: listObjectsMinimal,
                    variables: {
                        filter: filterExpr,
                        nextToken: nt
                    },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
        } catch (err) { console.error(err) }
        return {
            data: res
        };
    },
    insert: async (data) => {
        let input = {...data};
        if(input?.parentType) {
            delete input.parentType;
        }
        if (data.fotoToUpload) {
            // upload background foto first
            const uploadDetails = await uploadToS3Bucket(data.fotoToUpload[0]);
            if (!uploadDetails) {
                notify("Foto konnte nicht hochgeladen werden.", "error", 3000);
                console.error("Error on upload to S3.");
                throw Error("Foto konnte nicht hochgeladen werden.")
            }
            delete input.fotoToUpload;
            input = {...input, ...uploadDetails};
        }

        return API.graphql({
            query: createObject,
            variables: {input: input}
        }).then(() => {
            notify("Speichern erfolgreich", "success", 3000);
        }).catch((e) => {
            alert(JSON.stringify(e));
            console.error(e);
        });
    },
    update: async (key, data) => {
        let input = {...data, id: key};
        if (data.fotoToUpload) {
            // upload background foto first
            const uploadDetails = await uploadToS3Bucket(data.fotoToUpload[0]);
            if (!uploadDetails) {
                notify("Foto konnte nicht hochgeladen werden.", "error", 3000);
                console.error("Error on upload to S3.");
                throw Error("Foto konnte nicht hochgeladen werden.")
            }
            delete input.fotoToUpload;
            input = {...input, ...uploadDetails};
        }
        return API.graphql({
            query: updateObject,
            variables: {input: input}
        }).then(() => {
            notify("Editieren erfolgreich", "success", 3000);
        }).catch((e) => {
            alert(JSON.stringify(e));
            console.error(e);
        });
    },
    remove: async (key) => {
        try {
            const childs = await fetchChildObjects(key);
            if (childs.length>0) {
                notify("Error on Delete: Object has Sub-Objects. Please delete all Sub-Objects first.", "error", 1000);
            } else {
                return API.graphql({
                    query: deleteObject,
                    variables: {input: {id: key}},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }).then(() => {
                    notify("Löschen erfolgreich", "success", 3000);
                }).catch((e) => {
                    alert(e);
                });
            }
        } catch (err) {
            console.error(JSON.stringify(err));
            notify("Error on Delete", "error", 1000);
        }
    },
    byKey: (key) => {
        return API.graphql({
            query: getObject,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getObject: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No Object with Key ${key} found`, "error", 3000);
            });
    }
};

const onEditingStart = async (event) => {
    event.component.columnOption("type", "formItem", {visible: false});
    return event;
}

const onEditorPreparing = (e) => {
    if (e.parentType === "dataRow" && e.dataField === "address") {
        e.editorOptions.onValueChanged = (ev) => {
            let selectedItem = ev.component.option("selectedItem");
            e.setValue(selectedItem);
        };
    }
}

const onInitNewRow = (e) => {
    const parentID = e.data.parentID;
    if (parentID) {
        e.promise = API.graphql({
            query: getObject,
            variables: {id:parentID}
        }).then(({data:{getObject: parentObject}}) => {
            e.data.customerID = parentObject.customerID;
            e.data.groupRead = parentObject.groupRead;
            e.data.groupWrite = parentObject.groupWrite;
            e.data.street = parentObject.street;
            e.data.zipcode = parentObject.zipcode;
            e.data.city = parentObject.city;
            e.data.country = parentObject.country;
            e.data.latitude = parentObject.latitude;
            e.data.longitude = parentObject.longitude;
            e.data.altitude = parentObject.altitude;
            e.data.parentType = parentObject.type;
            if (parentObject.type === 'CITY') {
                e.data.type = 'BUILDING';
            } else if (parentObject.type === 'BUILDING') {
                e.data.type = 'ROOM';
            }
        });
    }
}


const setCellValue = (rowData, value) => {
    rowData.city = value.city;
    rowData.country = value.country;
    rowData.street = value.street;
    rowData.zipcode = value.zipcode;
    rowData.longitude = value.longitude;
    rowData.latitude = value.latitude;
}

const allowInserting = (e) => e.row.data.type !== 'ROOM';

function ObjectsList() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();

    const renderBackgroundItem = (cell) => {
        return (
            <div className="fileuploader-container">
                <FileUploader
                    id="file-uploader"
                    name={"background-uploader"}
                    selectButtonText={t('global.selectFotoBtnText')}
                    accept={"image/*"}
                    allowedFileExtensions={['.png', '.jpg', '.jpeg']}
                    multiple={false}
                    focusStateEnabled={false}
                    uploadMode="useForm"
                    showFileList={true}
                    onValueChanged={({value}) => cell.setValue(value)}
                />
            </div>
        )
    }

    if (authContext?.authState === AuthState.SignedIn) {
        return(
            <div className="ObjectsList">
                <Header />
                <div className={"Content full-width"}>
                    <h2 className={"headline"}>{t("objectslist.headline")}</h2>
                    <TreeList
                        dataSource={
                            new CustomStore(objectsStore)
                        }
                        remoteOperations={{
                            paging: false,
                            sorting: false,
                            filtering: true,
                        }}
                        showBorders={true}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        allowColumnReordering={true}
                        wordWrapEnabled={true}
                        keyExpr="id"
                        parentIdExpr="parentID"
                        rootValue={null}
                        repaintChangesOnly={true}
                        onEditingStart={onEditingStart}
                        onEditorPreparing={onEditorPreparing}
                        onInitNewRow={onInitNewRow}
                    >
                        <Toolbar>
                            {(authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                                <TItem
                                    name="addRowButton"
                                    location={"after"}
                                    locateInMenu={"auto"}
                                    onClick={() => navigate('/object-create')}
                                />
                                : null
                            }
                            <TItem
                                name="columnChooserButton"
                                locateInMenu="auto"
                                location="after"
                            />
                            <TItem name="searchPanel"  location={"after"} locateInMenu={"auto"}/>
                        </Toolbar>
                        { (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                            <Editing
                                mode={editing_mode}
                                useIcons={true}
                                allowAdding={allowInserting}
                                allowUpdating={true}
                                allowDeleting={true}>
                                <Popup title={t("objectslist.popup-title")} showTitle={false} height={"auto"} maxWidth={"1000px"} maxHeight={"100vh"}/>
                                <Form colCount={2} showValidationSummary={true}>
                                    <GroupItem caption={t("global.information")} colSpan={1}>
                                        <Item dataField="name" colSpan={1}>
                                            <RequiredRule/>
                                            <Label text={t("objectslist.col-name")}/>
                                        </Item>
                                        <Item dataField={"type"}>
                                            <RequiredRule/>
                                            <Label text={t("global.type")}/>
                                        </Item>
                                        <SimpleItem dataField={"description"} editorType="dxTextArea"
                                                    editorOptions={{height: "250"}}/>
                                        <Item dataField={"background"}>
                                            <Label text={t("global.current-background-picture")}/>
                                        </Item>
                                        <Item dataField={"fotoToUpload"}>
                                            <Label text={t("global.new-background-picture")}/>
                                        </Item>
                                    </GroupItem>
                                    <GroupItem caption={t("global.location")} colSpan={1}>
                                        <SimpleItem dataField={"address"}
                                                    editorType={'dxSelectBox'}
                                                    editorOptions={{
                                                        dataSource: new CustomStore({...geoLocationStore}),
                                                        displayExpr: (data) => data ? `${data.street ? data.street+', ' : ''}${data.zipcode} ${data.city}, ${data.country}` : null,
                                                        searchEnabled: true,
                                                        placeholder: t("global.addressSearchPlaceholderText")
                                                    }}
                                        >
                                            <Label text={t("global.address")}/>
                                        </SimpleItem>
                                        <Item dataField="street" colSpan={1}/>
                                        <Item dataField="zipcode" colSpan={1}><NumericRule/></Item>
                                        <Item dataField="city" colSpan={1}/>
                                        <Item dataField="country" colSpan={1}/>
                                        <Item dataField="longitude" colSpan={1}><NumericRule/></Item>
                                        <Item dataField="latitude" colSpan={1}><NumericRule/></Item>
                                        <Item dataField="altitude" colSpan={1}><NumericRule/></Item>
                                    </GroupItem>
                                </Form>
                            </Editing> : null }
                        <ColumnChooser enabled={true} mode="select"/>
                        <StateStoring
                            enabled={true}
                            type="localStorage"
                            storageKey="objectslist"
                        />
                        <LoadPanel enabled/>
                        <HeaderFilter visible={false}/>
                        <Scrolling
                            mode="standard" />
                        <Paging
                            enabled={true}
                            defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 25, 50, 'all']}
                            showInfo={true}/>
                        <SearchPanel visible={true} width={"auto"}/>
                        <Column
                            dataField="name"
                            caption={t("objectslist.col-name")}
                            allowSorting={true}
                            minWidth={200}
                            defaultSortIndex={0} defaultSortOrder="asc"
                        />
                        <Column
                            dataField="type"
                            caption={t("global.type")}
                            formItem={{visible: true}}
                        >
                            <Lookup dataSource={getFilteredTypes}/>
                        </Column>
                        {(authContext?.accesslevel === 1) ?
                            <Column dataField="customerID" caption={t("global.customer-name")}>
                                <Lookup dataSource={customersStore} valueExpr="id" displayExpr="name" searchEnabled={true}/>
                            </Column>
                            :null}
                        <Column
                            dataField="address"
                            setCellValue={setCellValue}
                            visible={false}
                        />
                        <Column
                            dataField="country"
                            caption={t("global.country")}
                        />
                        <Column
                            dataField="city"
                            caption={t("global.city")}
                        />
                        <Column
                            dataField="street"
                            caption={t("global.street")}
                        />
                        <Column
                            dataField="zipcode"
                            caption={t("global.zipcode")}
                        />
                        <Column
                            dataField="longitude"
                            caption={t("global.longitude")}
                            visible={false}
                        />
                        <Column
                            dataField="latitude"
                            caption={t("global.latitude")}
                            visible={false}
                        />
                        <Column
                            dataField="altitude"
                            caption={t("global.altitude")}
                            visible={false}
                        />
                        <Column
                            dataField="description"
                            caption={t("global.description")}
                        />
                        <Column
                            dataField="background"
                            caption={t("global.background-picture")}
                            visible={false}
                            editCellComponent={BackgroundImgFormItem}
                        />
                        <Column
                            dataField="fotoToUpload"
                            visible={false}
                            editCellRender={renderBackgroundItem}
                        />
                        <Column type="buttons" alignment={'right'}/>
                    </TreeList>
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default ObjectsList;
