export const REGEXP_OnlyLetters = RegExp("^[^0-9]+$")
export const REGEXP_OnlyDigits = RegExp("^[0-9]+$")
export const REGEXP_AlphaNumeric = RegExp("^[A-Za-z0-9 -]+$")
export const REGEXP_Phone = RegExp("^[+]?[0-9 ]{5,20}$")
export const MASK_Phone = {placeholder:"+4312345678912"};
export const REGEXP_Email = RegExp("^[A-Za-z0-9.!#$%&*+-=?^_{|}~]+[@]{1}[A-Za-z0-9.!#$%&*+-=?^_{|}~]+[.]{1}[A-Za-z]{2,3}$")

export function validateName(input) {
    return REGEXP_OnlyLetters.test(input)
}

export function validateEmail(input) {
    return REGEXP_Email.test(input);
}

export function validateZipcode(input) {
    return REGEXP_AlphaNumeric.test(input);
}

export function validatePhoneNumber(input) {
    return REGEXP_Phone.test(input);
}

export function isValidURL(url) {
    try { return Boolean(new URL(url)); }
    catch(e){ return false; }
};