/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTBToken = /* GraphQL */ `
  query GetTBToken($userId: ID!) {
    getTBToken(userId: $userId) {
      token
      refreshToken
      __typename
    }
  }
`;
export const getQuicksightEmbededUrlForRegisteredUser = /* GraphQL */ `
  query GetQuicksightEmbededUrlForRegisteredUser($email: String!) {
    getQuicksightEmbededUrlForRegisteredUser(email: $email) {
      embedUrl
      __typename
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices($id: ID!) {
    getDevices(id: $id) {
      id
      deviceTypeID
      deviceType {
        id
        name
        producer
        website
        deveui
        appeui
        appkey
        description
        status
        createdAt
        updatedAt
        tag
        wlDeviceProfileID
        wlDeviceProfile {
          id
          name
          rf_region
          lora_class
          createdAt
          updatedAt
          owner
        }
        wlServiceProfileID
        wlServiceProfile {
          id
          name
          createdAt
          updatedAt
          owner
        }
        destinationName
        destination {
          name
          expression
          expressionType
          decoder_name
          createdAt
          updatedAt
          owner
        }
        creator
        is3gDeviceType
        dreiDeviceProfileId
        tbDeviceProfileId
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        foto {
          bucket
          region
          key
        }
        background
        tag
        parentID
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      name
      serialNr
      deveui
      appeui
      appkey
      latitude
      longitude
      altitude
      description
      foto {
        bucket
        region
        key
        __typename
      }
      tag
      is3gSensor
      swapProcessStatus
      swap3gSensorData {
        newSerialNr
        measImportEndDateTime
        measImportStartDateTime
        __typename
      }
      carrier
      onboard_iotcore
      onboard_drei
      onboard_thingsboard
      createdAt
      updatedAt
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      owner
      sensor3gIndex {
        serialNr
        timestamp
        measEnd
        gsmSettings {
          acknowledgedByDevice
          syncMode
          measInt
          gsmSyncInt
          bleWakeUpInt
          bleWakeUpDuration
        }
        status
        measImportStartDateTime
        measImportEndDateTime
        groupRead
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceTypeID
        deviceType {
          id
          name
          producer
          website
          deveui
          appeui
          appkey
          description
          status
          createdAt
          updatedAt
          tag
          wlDeviceProfileID
          wlServiceProfileID
          destinationName
          creator
          is3gDeviceType
          owner
        }
        objectID
        object {
          id
          name
          type
          createdAt
          description
          street
          zipcode
          city
          country
          latitude
          longitude
          altitude
          mount_description
          background
          tag
          parentID
          creator
          customerID
          groupRead
          groupWrite
          updatedAt
          owner
        }
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        foto {
          bucket
          region
          key
        }
        tag
        is3gSensor
        swapProcessStatus
        swap3gSensorData {
          newSerialNr
          measImportEndDateTime
          measImportStartDateTime
        }
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        owner
        sensor3gIndex {
          serialNr
          timestamp
          measEnd
          status
          measImportStartDateTime
          measImportEndDateTime
          groupRead
          groupWrite
          createdAt
          updatedAt
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeviceType = /* GraphQL */ `
  query GetDeviceType($id: ID!) {
    getDeviceType(id: $id) {
      id
      name
      producer
      website
      deveui
      appeui
      appkey
      description
      status
      createdAt
      updatedAt
      tag
      wlDeviceProfileID
      wlDeviceProfile {
        id
        name
        rf_region
        lora_class
        createdAt
        updatedAt
        owner
        __typename
      }
      wlServiceProfileID
      wlServiceProfile {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      destinationName
      destination {
        name
        expression
        expressionType
        decoder_name
        decoder_lang
        createdAt
        updatedAt
        owner
        __typename
      }
      creator
      is3gDeviceType
      dreiDeviceProfileId
      tbDeviceProfileId
      owner
      __typename
    }
  }
`;
export const listDeviceTypes = /* GraphQL */ `
  query ListDeviceTypes(
    $filter: ModelDeviceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        producer
        website
        deveui
        appeui
        appkey
        description
        status
        createdAt
        updatedAt
        tag
        wlDeviceProfileID
        wlDeviceProfile {
          id
          name
          rf_region
          lora_class
          createdAt
          updatedAt
          owner
        }
        wlServiceProfileID
        wlServiceProfile {
          id
          name
          createdAt
          updatedAt
          owner
        }
        destinationName
        destination {
          name
          expression
          expressionType
          decoder_name
          createdAt
          updatedAt
          owner
        }
        creator
        is3gDeviceType
        dreiDeviceProfileId
        tbDeviceProfileId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWirelessDeviceProfiles = /* GraphQL */ `
  query GetWirelessDeviceProfiles($id: ID!) {
    getWirelessDeviceProfiles(id: $id) {
      id
      name
      rf_region
      lora_class
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWirelessDeviceProfiles = /* GraphQL */ `
  query ListWirelessDeviceProfiles(
    $filter: ModelWirelessDeviceProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWirelessDeviceProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        rf_region
        lora_class
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWirelessServiceProfiles = /* GraphQL */ `
  query GetWirelessServiceProfiles($id: ID!) {
    getWirelessServiceProfiles(id: $id) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWirelessServiceProfiles = /* GraphQL */ `
  query ListWirelessServiceProfiles(
    $filter: ModelWirelessServiceProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWirelessServiceProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDestination = /* GraphQL */ `
  query GetDestination($name: String!) {
    getDestination(name: $name) {
      name
      expression
      expressionType
      decoder_name
      decoder_lang
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDestinations = /* GraphQL */ `
  query ListDestinations(
    $name: String
    $filter: ModelDestinationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDestinations(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        expression
        expressionType
        decoder_name
        decoder_lang
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLoRaWANGateway = /* GraphQL */ `
  query GetLoRaWANGateway($id: ID!) {
    getLoRaWANGateway(id: $id) {
      id
      name
      producer
      website
      backhaulSimCard
      foto {
        bucket
        region
        key
        __typename
      }
      eui
      comments
      latitude
      longitude
      altitude
      country
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        foto {
          bucket
          region
          key
        }
        background
        tag
        parentID
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      wirelessGatewayId
      connstate {
        id
        state
        code
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      tag
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const listLoRaWANGateways = /* GraphQL */ `
  query ListLoRaWANGateways(
    $filter: ModelLoRaWANGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoRaWANGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        producer
        website
        backhaulSimCard
        foto {
          bucket
          region
          key
        }
        eui
        comments
        latitude
        longitude
        altitude
        country
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        objectID
        object {
          id
          name
          type
          createdAt
          description
          street
          zipcode
          city
          country
          latitude
          longitude
          altitude
          mount_description
          background
          tag
          parentID
          creator
          customerID
          groupRead
          groupWrite
          updatedAt
          owner
        }
        wirelessGatewayId
        connstate {
          id
          state
          code
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        tag
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGWConnState = /* GraphQL */ `
  query GetGWConnState($id: ID!) {
    getGWConnState(id: $id) {
      id
      state
      code
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGWConnStates = /* GraphQL */ `
  query ListGWConnStates(
    $filter: ModelGWConnStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGWConnStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        state
        code
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getObject = /* GraphQL */ `
  query GetObject($id: ID!) {
    getObject(id: $id) {
      id
      name
      type
      createdAt
      description
      street
      zipcode
      city
      country
      latitude
      longitude
      altitude
      mount_description
      foto {
        bucket
        region
        key
        __typename
      }
      background
      tag
      parentID
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      updatedAt
      owner
      __typename
    }
  }
`;
export const listObjects = /* GraphQL */ `
  query ListObjects(
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        foto {
          bucket
          region
          key
        }
        background
        tag
        parentID
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      email
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      address
      zipcode
      country
      phone
      newsletter
      agb_dsgvo
      dashboard_template_id
      status
      role
      userpool_id
      username
      tb_userID
      createdAt
      updatedAt
      ttl
      owner
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        email
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        address
        zipcode
        country
        phone
        newsletter
        agb_dsgvo
        dashboard_template_id
        status
        role
        userpool_id
        username
        tb_userID
        createdAt
        updatedAt
        ttl
        owner
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      bmonc_id
      bmonc_customer_name
      atu
      iban
      bic
      address
      zipcode
      country
      phone
      email
      website
      type
      level
      tb_tenantID
      tb_ownerID
      tb_parent_customerID
      tb_customerID
      parent_customerID
      parent_customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      createdAt
      updatedAt
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      templateID
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        templateID
        createdAt
        updatedAt
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDashboardTemplate = /* GraphQL */ `
  query GetDashboardTemplate($id: ID!) {
    getDashboardTemplate(id: $id) {
      id
      name
      description
      bucket
      key
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const listDashboardTemplates = /* GraphQL */ `
  query ListDashboardTemplates(
    $filter: ModelDashboardTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        bucket
        key
        createdAt
        updatedAt
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatistics = /* GraphQL */ `
  query GetStatistics($id: ID!) {
    getStatistics(id: $id) {
      id
      customer
      customerType
      parentCustomerID
      nr_admins
      nr_users
      nr_subcustomerusers
      nr_sensors
      nr_gateways
      nr_cities
      nr_buildings
      nr_zones
      nr_rooms
      createdAt
      updatedAt
      groupRead
      owner
      __typename
    }
  }
`;
export const listStatistics = /* GraphQL */ `
  query ListStatistics(
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer
        customerType
        parentCustomerID
        nr_admins
        nr_users
        nr_subcustomerusers
        nr_sensors
        nr_gateways
        nr_cities
        nr_buildings
        nr_zones
        nr_rooms
        createdAt
        updatedAt
        groupRead
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DevicesByDevEUI = /* GraphQL */ `
  query DevicesByDevEUI(
    $deveui: String
    $sortDirection: ModelSortDirection
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DevicesByDevEUI(
      deveui: $deveui
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceTypeID
        deviceType {
          id
          name
          producer
          website
          deveui
          appeui
          appkey
          description
          status
          createdAt
          updatedAt
          tag
          wlDeviceProfileID
          wlServiceProfileID
          destinationName
          creator
          is3gDeviceType
          owner
        }
        objectID
        object {
          id
          name
          type
          createdAt
          description
          street
          zipcode
          city
          country
          latitude
          longitude
          altitude
          mount_description
          background
          tag
          parentID
          creator
          customerID
          groupRead
          groupWrite
          updatedAt
          owner
        }
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        foto {
          bucket
          region
          key
        }
        tag
        is3gSensor
        swapProcessStatus
        swap3gSensorData {
          newSerialNr
          measImportEndDateTime
          measImportStartDateTime
        }
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        owner
        sensor3gIndex {
          serialNr
          timestamp
          measEnd
          status
          measImportStartDateTime
          measImportEndDateTime
          groupRead
          groupWrite
          createdAt
          updatedAt
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DeviceByName = /* GraphQL */ `
  query DeviceByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DeviceByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceTypeID
        deviceType {
          id
          name
          producer
          website
          deveui
          appeui
          appkey
          description
          status
          createdAt
          updatedAt
          tag
          wlDeviceProfileID
          wlServiceProfileID
          destinationName
          creator
          is3gDeviceType
          owner
        }
        objectID
        object {
          id
          name
          type
          createdAt
          description
          street
          zipcode
          city
          country
          latitude
          longitude
          altitude
          mount_description
          background
          tag
          parentID
          creator
          customerID
          groupRead
          groupWrite
          updatedAt
          owner
        }
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        foto {
          bucket
          region
          key
        }
        tag
        is3gSensor
        swapProcessStatus
        swap3gSensorData {
          newSerialNr
          measImportEndDateTime
          measImportStartDateTime
        }
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        owner
        sensor3gIndex {
          serialNr
          timestamp
          measEnd
          status
          measImportStartDateTime
          measImportEndDateTime
          groupRead
          groupWrite
          createdAt
          updatedAt
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DevicesBySerialNr = /* GraphQL */ `
  query DevicesBySerialNr(
    $serialNr: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DevicesBySerialNr(
      serialNr: $serialNr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceTypeID
        deviceType {
          id
          name
          producer
          website
          deveui
          appeui
          appkey
          description
          status
          createdAt
          updatedAt
          tag
          wlDeviceProfileID
          wlServiceProfileID
          destinationName
          creator
          is3gDeviceType
          owner
        }
        objectID
        object {
          id
          name
          type
          createdAt
          description
          street
          zipcode
          city
          country
          latitude
          longitude
          altitude
          mount_description
          background
          tag
          parentID
          creator
          customerID
          groupRead
          groupWrite
          updatedAt
          owner
        }
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        foto {
          bucket
          region
          key
        }
        tag
        is3gSensor
        swapProcessStatus
        swap3gSensorData {
          newSerialNr
          measImportEndDateTime
          measImportStartDateTime
        }
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        owner
        sensor3gIndex {
          serialNr
          timestamp
          measEnd
          status
          measImportStartDateTime
          measImportEndDateTime
          groupRead
          groupWrite
          createdAt
          updatedAt
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DevicesByCarrier = /* GraphQL */ `
  query DevicesByCarrier(
    $carrier: Carrier
    $sortDirection: ModelSortDirection
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DevicesByCarrier(
      carrier: $carrier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceTypeID
        objectID
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        tag
        is3gSensor
        swapProcessStatus
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DevicesByCustomerId = /* GraphQL */ `
  query DevicesByCustomerId(
    $customerID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DevicesByCustomerId(
      customerID: $customerID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceTypeID
        objectID
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        tag
        is3gSensor
        swapProcessStatus
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DeviceTypesByStatus = /* GraphQL */ `
  query DeviceTypesByStatus(
    $status: DeviceStatus
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DeviceTypesByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        producer
        website
        deveui
        appeui
        appkey
        description
        status
        createdAt
        updatedAt
        tag
        wlDeviceProfileID
        wlDeviceProfile {
          id
          name
          rf_region
          lora_class
          createdAt
          updatedAt
          owner
        }
        wlServiceProfileID
        wlServiceProfile {
          id
          name
          createdAt
          updatedAt
          owner
        }
        destinationName
        destination {
          name
          expression
          expressionType
          decoder_name
          createdAt
          updatedAt
          owner
        }
        creator
        is3gDeviceType
        dreiDeviceProfileId
        tbDeviceProfileId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const LoRaWANGatewayByEUI = /* GraphQL */ `
  query LoRaWANGatewayByEUI(
    $eui: String
    $sortDirection: ModelSortDirection
    $filter: ModelLoRaWANGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LoRaWANGatewayByEUI(
      eui: $eui
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        producer
        website
        backhaulSimCard
        foto {
          bucket
          region
          key
        }
        eui
        comments
        latitude
        longitude
        altitude
        country
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        objectID
        object {
          id
          name
          type
          createdAt
          description
          street
          zipcode
          city
          country
          latitude
          longitude
          altitude
          mount_description
          background
          tag
          parentID
          creator
          customerID
          groupRead
          groupWrite
          updatedAt
          owner
        }
        wirelessGatewayId
        connstate {
          id
          state
          code
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        tag
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const LoRaWANGatewayByName = /* GraphQL */ `
  query LoRaWANGatewayByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelLoRaWANGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LoRaWANGatewayByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        producer
        website
        backhaulSimCard
        foto {
          bucket
          region
          key
        }
        eui
        comments
        latitude
        longitude
        altitude
        country
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        objectID
        object {
          id
          name
          type
          createdAt
          description
          street
          zipcode
          city
          country
          latitude
          longitude
          altitude
          mount_description
          background
          tag
          parentID
          creator
          customerID
          groupRead
          groupWrite
          updatedAt
          owner
        }
        wirelessGatewayId
        connstate {
          id
          state
          code
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        tag
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectByParentID = /* GraphQL */ `
  query ObjectByParentID(
    $parentID: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectByParentID(
      parentID: $parentID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        foto {
          bucket
          region
          key
        }
        background
        tag
        parentID
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectByName = /* GraphQL */ `
  query ObjectByName(
    $name: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectByName(
      name: $name
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        foto {
          bucket
          region
          key
        }
        background
        tag
        parentID
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const objectByCustomerID = /* GraphQL */ `
  query ObjectByCustomerID(
    $customerID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectByCustomerID(
      customerID: $customerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        foto {
          bucket
          region
          key
        }
        background
        tag
        parentID
        creator
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const UserByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        email
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        address
        zipcode
        country
        phone
        newsletter
        agb_dsgvo
        dashboard_template_id
        status
        role
        userpool_id
        username
        tb_userID
        createdAt
        updatedAt
        ttl
        owner
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const UserByStatus = /* GraphQL */ `
  query UserByStatus(
    $status: UserStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        email
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        address
        zipcode
        country
        phone
        newsletter
        agb_dsgvo
        dashboard_template_id
        status
        role
        userpool_id
        username
        tb_userID
        createdAt
        updatedAt
        ttl
        owner
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const UserByRole = /* GraphQL */ `
  query UserByRole(
    $role: UserRole
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByRole(
      role: $role
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        email
        customerID
        customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        address
        zipcode
        country
        phone
        newsletter
        agb_dsgvo
        dashboard_template_id
        status
        role
        userpool_id
        username
        tb_userID
        createdAt
        updatedAt
        ttl
        owner
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CustomerbyCustomerName = /* GraphQL */ `
  query CustomerbyCustomerName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CustomerbyCustomerName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CompanybyBmoncID = /* GraphQL */ `
  query CompanybyBmoncID(
    $bmonc_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CompanybyBmoncID(
      bmonc_id: $bmonc_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CompanybyBmoncCustomerName = /* GraphQL */ `
  query CompanybyBmoncCustomerName(
    $bmonc_customer_name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CompanybyBmoncCustomerName(
      bmonc_customer_name: $bmonc_customer_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CompanybyParentCustomerID = /* GraphQL */ `
  query CompanybyParentCustomerID(
    $parent_customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CompanybyParentCustomerID(
      parent_customerID: $parent_customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        parent_customer {
          id
          name
          bmonc_id
          bmonc_customer_name
          atu
          iban
          bic
          address
          zipcode
          country
          phone
          email
          website
          type
          level
          tb_tenantID
          tb_ownerID
          tb_parent_customerID
          tb_customerID
          parent_customerID
          createdAt
          updatedAt
          groupRead
          groupWrite
          owner
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DasboardIdByName = /* GraphQL */ `
  query DasboardIdByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DasboardIdByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        templateID
        createdAt
        updatedAt
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DasboardTemplateIdByName = /* GraphQL */ `
  query DasboardTemplateIdByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DasboardTemplateIdByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        bucket
        key
        createdAt
        updatedAt
        groupRead
        groupWrite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const StatisticsByCustomer = /* GraphQL */ `
  query StatisticsByCustomer(
    $customer: String
    $sortDirection: ModelSortDirection
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatisticsByCustomer(
      customer: $customer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer
        customerType
        parentCustomerID
        nr_admins
        nr_users
        nr_subcustomerusers
        nr_sensors
        nr_gateways
        nr_cities
        nr_buildings
        nr_zones
        nr_rooms
        createdAt
        updatedAt
        groupRead
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const StatisticsByCustomerType = /* GraphQL */ `
  query StatisticsByCustomerType(
    $customerType: StatCustomerType
    $sortDirection: ModelSortDirection
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StatisticsByCustomerType(
      customerType: $customerType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer
        customerType
        parentCustomerID
        nr_admins
        nr_users
        nr_subcustomerusers
        nr_sensors
        nr_gateways
        nr_cities
        nr_buildings
        nr_zones
        nr_rooms
        createdAt
        updatedAt
        groupRead
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensor3gIndex = /* GraphQL */ `
  query GetSensor3gIndex($serialNr: ID!) {
    getSensor3gIndex(serialNr: $serialNr) {
      serialNr
      timestamp
      measEnd
      gsmSettings {
        acknowledgedByDevice
        syncMode
        measInt
        gsmSyncInt
        bleWakeUpInt
        bleWakeUpDuration
        __typename
      }
      status
      measImportStartDateTime
      measImportEndDateTime
      groupRead
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSensor3gIndices = /* GraphQL */ `
  query ListSensor3gIndices(
    $serialNr: ID
    $filter: ModelSensor3gIndexFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSensor3gIndices(
      serialNr: $serialNr
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        serialNr
        timestamp
        measEnd
        gsmSettings {
          acknowledgedByDevice
          syncMode
          measInt
          gsmSyncInt
          bleWakeUpInt
          bleWakeUpDuration
        }
        status
        measImportStartDateTime
        measImportEndDateTime
        groupRead
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const Sensor3gIndexByStatus = /* GraphQL */ `
  query Sensor3gIndexByStatus(
    $status: Sensor3gStatus
    $sortDirection: ModelSortDirection
    $filter: ModelSensor3gIndexFilterInput
    $limit: Int
    $nextToken: String
  ) {
    Sensor3gIndexByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serialNr
        timestamp
        measEnd
        gsmSettings {
          acknowledgedByDevice
          syncMode
          measInt
          gsmSyncInt
          bleWakeUpInt
          bleWakeUpDuration
        }
        status
        measImportStartDateTime
        measImportEndDateTime
        groupRead
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
