import React, {useContext, useEffect, useState} from "react";
import {API} from "aws-amplify";
import {
    GroupItem,
    Item,
    RequiredRule,
    SimpleItem,
    ButtonItem,
    AsyncRule,
    StringLengthRule,
    NumericRule,
    PatternRule,
    Label, EmptyItem
} from "devextreme-react/form";
import {Form} from "devextreme-react";
import {createDevices, createSensor3gIndex} from "../../graphql/mutations";
import notify from "devextreme/ui/notify";
import {idgenerator} from "../../settings/functions";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {useTranslation} from "react-i18next";
import CustomStore from "devextreme/data/custom_store";
import {deviceTypeStore} from "../../common/deviceTypesStore";
import ErrorPage from "../Pages/ErrorPage";
import {customersStore} from "../../common/customersStore";
import {useNavigate} from "react-router-dom";
import Map from "../Map";
import {listObjectsMinimal} from "../../graphql/customQueries";

const deveuiPattern = '^[a-zA-Z0-9]+$';
const appeuiPattern = '^[a-zA-Z0-9]+$';
const appkeyPattern = '^[a-zA-Z0-9]+$';

const deveuiValidation = async (params) => {
    const deveui = params.value;
    let not_exists= false;
    try {
        const {data : {DevicesByDevEUI: {items: page}}} = await API.graphql({
            query: "query DevicesByDevEUI {DevicesByDevEUI(deveui: \""+deveui+"\") {items {id}}}",
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (page.length===0){
            not_exists = true;
        }
    } catch (err) { console.error(err) }
    return not_exists;
}

const serialNrValidation = async (params) => {
    const serialNr = params.value;
    let not_exists= false;
    try {
        const {data : {DevicesBySerialNr: {items: page}}} = await API.graphql({
            query: "query DevicesBySerialNr {DevicesBySerialNr(serialNr: \""+serialNr+"\") {items {id}}}",
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (page.length===0){
            not_exists = true;
        }
    } catch (err) { console.error(err) }
    return not_exists;
}

function DeviceRegisterForm() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [objects, setObjects] = useState([]);
    const [formInputs, setFormInputs] = useState({
        customer: (authContext?.accesslevel===1) ? null : authContext?.userInfoFromDB?.customer,
        deviceTypeID: null,
        objectID: null,
        name: null,
        carrier:null,
        serialNr: null,
        deveui: null,
        appeui: null,
        appkey: null,
        longitude: null, // Geo Position
        latitude: null, // Geo Position
        altitude: null, // Geo Position
        description: null, // Device description / Einbaubeschreibung
        creator: authContext.user.username,
        tag: null, //not used yet
        is3gSensor: false,
        measImportFrom: new Date()
    });

    const renderStreetMap = () => {
        return (
            <Map
                longitude={formInputs.longitude}
                latitude={formInputs.latitude}
                title={formInputs.name}
            />
        )
    }

    // Fetches all Room and Zone Objects
    const fetchObjects = async () => {
        try {
            let filter =  {or: [ {type: {eq: 'ROOM'}}, {type: {eq: 'ZONE'}}]};
            if (formInputs.customer) {
                filter = {and: [{or: [ {type: {eq: 'ROOM'}}, {type: {eq: 'ZONE'}}]}, {customerID: {eq: formInputs.customer.id}}]}
            }
            let res = [];
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listObjects: {items: page, nextToken}}} = await API.graphql({
                    query: listObjectsMinimal,
                    variables: {nextToken: nt,filter: filter},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null);
            setObjects(res);
        } catch (err) { console.error(err)}
    }

    // handles the onboard of the device
    const handleDeviceRegister = (e) => {
        e.preventDefault();
        if (authContext?.authState !== AuthState.SignedIn) {alert(t('global.notAuthenticated')); e.cancel = true; return;}
        if (!formInputs.customer) {alert(t('devicescreate.selectCustomer')); e.cancel = true; return;}
        if (!formInputs.objectID) {alert(t('devicescreate.selectObject'));e.cancel = true; return;}
        if (!formInputs.deviceTypeID) {alert(t('devicescreate.selectDeviceType'));e.cancel = true; return;}
        let customer_formatted = formInputs.customer.bmonc_customer_name.replace(/ /g, "-");
        let groupWrite = [];
        let groupRead = [];
        if (authContext?.groups.includes("BMONCMaster")) { // use the selected groups
            groupWrite.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-USER")
        } else if (authContext?.accesslevel===2) { // admin
            groupWrite.push(customer_formatted+"-ADMIN");
            groupRead.push(customer_formatted+"-ADMIN");
            if (formInputs.customer.level === "CUSTOMER") {
                groupRead.push(customer_formatted+"-USER");
            } else {
                groupRead.push(customer_formatted+"-SUBCUSTOMERUSER");
            }
        } else {
            console.error(t('devicescreate.permissionError'));
        }
        let input = {
            id: idgenerator(),
            deviceTypeID: formInputs.deviceTypeID,
            objectID: formInputs.objectID,
            name: formInputs.name,
            serialNr: formInputs.is3gSensor ? formInputs.serialNr : 'not3gSensor',
            deveui: formInputs.is3gSensor ? formInputs.serialNr : formInputs.deveui?.toLowerCase(),
            appeui: formInputs.is3gSensor ? "" : formInputs.appeui,
            appkey: formInputs.is3gSensor ? "" : formInputs.appkey,
            description:  formInputs.description ? formInputs.description : "",
            longitude: formInputs.longitude ? formInputs.longitude: "", // Geo Position
            latitude: formInputs.latitude ? formInputs.latitude : "", // Geo Position
            altitude: formInputs.altitude ? formInputs.altitude : "", // Geo Position
            //tag: '', // example: owner = Kunde
            is3gSensor: formInputs.is3gSensor,
            creator: formInputs.creator,
            customerID: formInputs.customer ? formInputs.customer.id : null,
            carrier: formInputs.is3gSensor ? 'GSM' : formInputs.carrier,
            onboard_iotcore: formInputs.is3gSensor || formInputs.carrier === 'IOT_CORE' ? 'ON_BOARDING' : 'NO_ACTION',
            onboard_drei: !formInputs.is3gSensor && formInputs.carrier === 'DREI' ? 'ON_BOARDING' : 'NO_ACTION',
            onboard_thingsboard: 'ON_BOARDING',
            groupRead: groupRead,
            groupWrite: groupWrite
        }

        API.graphql({
            query: createDevices,
            variables: {
                input: input,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            if (formInputs.is3gSensor) {
                API.graphql({
                    query: createSensor3gIndex,
                    variables: {
                        input: {
                            serialNr: formInputs.serialNr,
                            timestamp: new Date().toISOString(),
                            measEnd: 0,
                            measImportStartDateTime: formInputs?.measImportFrom ? formInputs.measImportFrom.toISOString() : null,
                            status: "ACTIVE"
                        },
                    },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
            }
            notify(t('devicescreate.createSuccess'), "success", 3000);
            navigate(-1);
        }).catch((e) => {
            alert(JSON.stringify(e));
            console.error(e);
            e.cancel = true;
        });
    }

    // Use the submitted data to set the state
    const handleChange = (event, name) => {
        if (!event.event) return
        if (!name) { // SelectBox or DateBox
            const {name, value} = event.event.target
            setFormInputs({ ...formInputs,
                [name]: value
            });
        } else { // TextBox or similar
            setFormInputs({ ...formInputs,
                [name]: event.value
            });
        }
    }

    const handleCustomerChange = (e) => {
        let sel_customer = e.value;

        // this prevents the selection of a not yet physically created customer in thingsboard
        if (!sel_customer || !sel_customer['tb_customerID']) {
            notify("Der Kunde hat noch keine Benutzer oder ist noch im DEMO Modus.", "warning", 3000);
            e.cancel = true;
            return;
        }

        setFormInputs({ ...formInputs,
            customer: sel_customer,
            objectID: null
        });
    }

    // handles devicetype change and some autocomplete
    const handleDeviceTypeChange = (e) => {
        // change AppEui, AppKey and Description
        const devtype = e.value;
        if (devtype?.is3gDeviceType === true) { // is a 3g sensor
            setFormInputs({ ...formInputs,
                deviceTypeID: devtype.id,
                devicetype: devtype,
                appeui: null,
                appkey: null,
                description: (devtype?.description ? devtype.description: null),
                is3gSensor: true,
            });
        } else {
            setFormInputs({ ...formInputs,
                deviceTypeID: devtype.id,
                devicetype: devtype,
                appeui: (devtype?.appeui ? devtype.appeui: null),
                appkey: (devtype?.appkey ? devtype.appkey: null),
                description: (devtype?.description ? devtype.description: null),
                is3gSensor: false,
            });
        }
    }

    const handleCarrierChange = (e) => setFormInputs({...formInputs, carrier: e.value});

    // handles object change and some geo location autocomplete
    const handleObjectChange = (e) => {
        const object = e.value;
        setFormInputs({ ...formInputs,
            objectID: object.id,
            longitude: (object?.longitude ? object.longitude: null),
            latitude: (object?.latitude ? object.latitude: null),
            altitude: (object?.altitude ? object.altitude: null)
        });
    }

    useEffect(() => {
        fetchObjects();
    }, []);

    if (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) {
        return (
            <div className={"DeviceRegisterForm"}>
                <Header />
                <div className={"Content"}>
                    <h2 className={"headline"}>{t("devicescreate.headline")}</h2>
                    <form action="register-device-action" onSubmit={handleDeviceRegister}>
                        <Form colCount={2}>
                            {(authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                                <GroupItem caption={t("global.customer")} colSpan={2}>
                                    <SimpleItem dataField={"customer"}
                                                editorType={'dxSelectBox'}
                                                editorOptions={{
                                                    dataSource: new CustomStore({...customersStore,
                                                            onLoading(loadOptions) {
                                                                loadOptions.sorting = authContext.accesslevel === 1 ? "bmonc_customer_name" : "name"
                                                            }
                                                        }),
                                                    onValueChanged: handleCustomerChange,
                                                    displayExpr: authContext?.accesslevel === 1 ? "bmonc_customer_name" : "name",
                                                    searchEnabled: true,
                                                }}
                                    >
                                        <RequiredRule/>
                                        <Label text={t("global.customer")}/>
                                    </SimpleItem>
                                </GroupItem>
                                : null}
                            <GroupItem caption={t("global.location")} colSpan={1}>
                                <SimpleItem dataField={"object"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: {
                                                    store: objects,
                                                    filter: formInputs.customer ? ["customerID", "=", formInputs.customer.id] : null,
                                                    sort: "name"
                                                },
                                                onValueChanged: handleObjectChange,
                                                displayExpr: "name",
                                                searchEnabled: true,
                                            }}
                                >
                                    <RequiredRule/>
                                    <Label text={t("global.relation")}/>
                                </SimpleItem>
                                <Item dataField="longitude" colSpan={1} editorOptions={{
                                    value: formInputs.longitude,
                                    onValueChanged: (e) => handleChange(e, "longitude")
                                }}>
                                    <NumericRule/>
                                    <Label text={t("global.longitude")}/>
                                </Item>
                                <Item dataField="latitude" colSpan={1} editorOptions={{
                                    value: formInputs.latitude,
                                    onValueChanged: (e) => handleChange(e, "latitude")
                                }}>
                                    <NumericRule/>
                                    <Label text={t("global.latitude")}/>
                                </Item>
                                <Item dataField="altitude" colSpan={1} editorOptions={{
                                    value: formInputs.altitude,
                                    onValueChanged: (e) => handleChange(e, "altitude")
                                }}>
                                    <NumericRule/>
                                    <Label text={t("global.altitude")}/>
                                </Item>
                                <SimpleItem dataField={"streetmap"}
                                            render={renderStreetMap}
                                >
                                    <Label text={t("global.map")}/>
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem caption={t("global.information")} colSpan={1}>
                                <SimpleItem dataField={"devicetype"}
                                            editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: new CustomStore({...deviceTypeStore}),
                                                onValueChanged: handleDeviceTypeChange,
                                                displayExpr: 'name',
                                                searchEnabled: true
                                            }}
                                >
                                    <RequiredRule/>
                                    <Label text={t("global.sensortype")}/>
                                </SimpleItem>
                                <Item dataField="name" colSpan={1}
                                      editorOptions={{
                                          value: formInputs.name,
                                          onValueChanged: (e) => handleChange(e, "name")
                                      }}>
                                    <RequiredRule/>
                                    <Label text={t("global.name")}/>
                                </Item>
                                {!formInputs.is3gSensor ? <SimpleItem dataField={"carrier"} editorType={'dxSelectBox'}
                                            editorOptions={{
                                                dataSource: t('carrierOptions', {returnObjects: true}),
                                                onValueChanged: handleCarrierChange,
                                                valueExpr: 'value',
                                                displayExpr: 'name',
                                                searchEnabled: true,
                                            }}
                                >
                                    <RequiredRule/>
                                    <Label text={t("global.carrier")}/>
                                </SimpleItem> : null }
                                {formInputs.is3gSensor ? <Item dataField="serialNr" colSpan={1}
                                                                editorOptions={{
                                                                    value: formInputs.serialNr,
                                                                    onValueChanged: (e) => handleChange(e, "serialNr")
                                                                }}>
                                        <RequiredRule/>
                                        <AsyncRule message={t("devicescreate.duplicateSerialNr")}
                                                   validationCallback={serialNrValidation}/>
                                        <Label text={t("global.serialNr")}/>
                                    </Item>
                                    : null}
                                {formInputs.is3gSensor ? <Item dataField="measImportFrom" colSpan={1}
                                                                     editorType="dxDateBox"
                                                               editorOptions={{
                                                                   type: 'datetime',
                                                                   value: formInputs.measImportFrom,
                                                                   onValueChanged: (e) => handleChange(e, "measImportFrom")
                                                               }}>
                                        <RequiredRule/>
                                        <Label text={t("global.measImportFrom")}/>
                                    </Item>
                                    : null}
                                {!formInputs.is3gSensor ? <Item dataField="deveui" colSpan={1}
                                                                editorOptions={{
                                                                    value: formInputs.deveui,
                                                                    onValueChanged: (e) => handleChange(e, "deveui")
                                                                }}>
                                        <RequiredRule/>
                                        <StringLengthRule message={t("global.stringLength16Hex")} min={16} max={16}/>
                                        <PatternRule message={t("global.alphaNumericalChars")}
                                                     pattern={deveuiPattern}/>
                                        <AsyncRule message={t("devicescreate.duplicateDevEUI")}
                                                   validationCallback={deveuiValidation}/>
                                        <Label text={t("global.deveui")}/>
                                    </Item>
                                    : null}
                                {!formInputs.is3gSensor ? <Item dataField="appeui" colSpan={1}
                                                                editorOptions={{
                                                                    value: formInputs.appeui,
                                                                    onValueChanged: (e) => handleChange(e, "appeui")
                                                                }}>
                                        <RequiredRule/>
                                        <StringLengthRule min={16} max={16} message={t("global.stringLength16Hex")}/>
                                        <PatternRule message={t("global.alphaNumericalChars")}
                                                     pattern={appeuiPattern}/>
                                        <Label text={t("global.appeui")}/>
                                    </Item>
                                    : null}
                                {!formInputs.is3gSensor ? <Item dataField="appkey" colSpan={1}
                                                                editorOptions={{
                                                                    value: formInputs.appkey,
                                                                    onValueChanged: (e) => handleChange(e, "appkey")
                                                                }}>
                                        <RequiredRule/>
                                        <StringLengthRule min={32} max={32} message={t("global.stringLength32Hex")}/>
                                        <PatternRule message={t("global.alphaNumericalChars")}
                                                     pattern={appkeyPattern}/>
                                        <Label text={t("global.appkey")}/>
                                    </Item>
                                    : null}
                                <SimpleItem dataField={"description"} editorType="dxTextArea" editorOptions={{
                                    height: "150",
                                    value: formInputs.description,
                                    onValueChanged: (e) => handleChange(e, "description")
                                }}><Label text={t("global.description")}/></SimpleItem>
                            </GroupItem>}
                            <EmptyItem/>
                            <GroupItem cssClass={"ButtonGroupItem"} colCount={2}>
                                <ButtonItem horizontalAlignment="right"
                                            buttonOptions={{
                                                text: t("devicescreate.button1"),
                                                type: 'success',
                                                useSubmitBehavior: true
                                            }}/>
                                <ButtonItem horizontalAlignment="right"
                                            cssClass="cancel-button"
                                            buttonOptions={{
                                                text: t("global.cancel"),
                                                type: 'cancel',
                                                onClick: () => {
                                                    navigate(-1);
                                                }
                                            }}/>
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default DeviceRegisterForm;