import React from "react";
import '../App.css';
import { LoadIndicator } from 'devextreme-react/load-indicator';

function Tile({href, onClick, text, loading = false, isHidden = false}) {
    return ( href ?
            <a href={href} target="_blank" rel={"noreferrer"}>
                <div className={`Tile ${isHidden ? "hiddenTile" : ""}`}>
                    {
                        loading ?
                            <h2><LoadIndicator /></h2>
                            :
                            <h2>{text}</h2>
                    }
                </div>
            </a>
                :
            <div className={`Tile ${isHidden ? "hiddenTile" : ""}`} onClick={onClick}>
                <h2>{text}</h2>
            </div>
    );
}

export default Tile;