import {API} from "aws-amplify";
import notify from "devextreme/ui/notify";
import {getWirelessDeviceProfiles, listWirelessDeviceProfiles
} from "../graphql/queries";

export const wirelessDeviceProfilesStore = {
    key: 'id',
    load: async (loadOptions) => {
        let deviceProfiles = [];
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data: {listWirelessDeviceProfiles: {items: page, nextToken}}} = await API.graphql({
                query: listWirelessDeviceProfiles,
                variable: {nextToken: nt},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            deviceProfiles = deviceProfiles.concat(page);
        } while (nt !== null)
        deviceProfiles.sort((a, b) => a?.name?.localeCompare(b?.name))
        return {
            data: deviceProfiles
        };
    },
    byKey: (key) => {
        return API.graphql({
            query: getWirelessDeviceProfiles,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getWirelessDeviceProfiles: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No Wireless Device Profile with Key ${key} found`, "error", 3000);
            });
    }
};