/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevices = /* GraphQL */ `
  mutation CreateDevices(
    $input: CreateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    createDevices(input: $input, condition: $condition) {
      id
      deviceTypeID
      deviceType {
        id
        name
        producer
        website
        deveui
        appeui
        appkey
        description
        status
        createdAt
        updatedAt
        tag
        wlDeviceProfileID
        wlServiceProfileID
        destinationName
        creator
        is3gDeviceType
        dreiDeviceProfileId
        tbDeviceProfileId
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        background
        tag
        parentID
        creator
        customerID
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      name
      serialNr
      deveui
      appeui
      appkey
      latitude
      longitude
      altitude
      description
      foto {
        bucket
        region
        key
        __typename
      }
      tag
      is3gSensor
      swapProcessStatus
      swap3gSensorData {
        newSerialNr
        measImportEndDateTime
        measImportStartDateTime
        __typename
      }
      carrier
      onboard_iotcore
      onboard_drei
      onboard_thingsboard
      createdAt
      updatedAt
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      owner
      sensor3gIndex {
        serialNr
        timestamp
        measEnd
        status
        measImportStartDateTime
        measImportEndDateTime
        groupRead
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateDevices = /* GraphQL */ `
  mutation UpdateDevices(
    $input: UpdateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    updateDevices(input: $input, condition: $condition) {
      id
      deviceTypeID
      deviceType {
        id
        name
        producer
        website
        deveui
        appeui
        appkey
        description
        status
        createdAt
        updatedAt
        tag
        wlDeviceProfileID
        wlServiceProfileID
        destinationName
        creator
        is3gDeviceType
        dreiDeviceProfileId
        tbDeviceProfileId
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        background
        tag
        parentID
        creator
        customerID
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      name
      serialNr
      deveui
      appeui
      appkey
      latitude
      longitude
      altitude
      description
      foto {
        bucket
        region
        key
        __typename
      }
      tag
      is3gSensor
      swapProcessStatus
      swap3gSensorData {
        newSerialNr
        measImportEndDateTime
        measImportStartDateTime
        __typename
      }
      carrier
      onboard_iotcore
      onboard_drei
      onboard_thingsboard
      createdAt
      updatedAt
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      owner
      sensor3gIndex {
        serialNr
        timestamp
        measEnd
        status
        measImportStartDateTime
        measImportEndDateTime
        groupRead
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteDevices = /* GraphQL */ `
  mutation DeleteDevices(
    $input: DeleteDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    deleteDevices(input: $input, condition: $condition) {
      id
      deviceTypeID
      deviceType {
        id
        name
        producer
        website
        deveui
        appeui
        appkey
        description
        status
        createdAt
        updatedAt
        tag
        wlDeviceProfileID
        wlServiceProfileID
        destinationName
        creator
        is3gDeviceType
        dreiDeviceProfileId
        tbDeviceProfileId
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        background
        tag
        parentID
        creator
        customerID
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      name
      serialNr
      deveui
      appeui
      appkey
      latitude
      longitude
      altitude
      description
      foto {
        bucket
        region
        key
        __typename
      }
      tag
      is3gSensor
      swapProcessStatus
      swap3gSensorData {
        newSerialNr
        measImportEndDateTime
        measImportStartDateTime
        __typename
      }
      carrier
      onboard_iotcore
      onboard_drei
      onboard_thingsboard
      createdAt
      updatedAt
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      owner
      sensor3gIndex {
        serialNr
        timestamp
        measEnd
        status
        measImportStartDateTime
        measImportEndDateTime
        groupRead
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createDeviceType = /* GraphQL */ `
  mutation CreateDeviceType(
    $input: CreateDeviceTypeInput!
    $condition: ModelDeviceTypeConditionInput
  ) {
    createDeviceType(input: $input, condition: $condition) {
      id
      name
      producer
      website
      deveui
      appeui
      appkey
      description
      status
      createdAt
      updatedAt
      tag
      wlDeviceProfileID
      wlDeviceProfile {
        id
        name
        rf_region
        lora_class
        createdAt
        updatedAt
        owner
        __typename
      }
      wlServiceProfileID
      wlServiceProfile {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      destinationName
      destination {
        name
        expression
        expressionType
        decoder_name
        decoder_lang
        createdAt
        updatedAt
        owner
        __typename
      }
      creator
      is3gDeviceType
      dreiDeviceProfileId
      tbDeviceProfileId
      owner
      __typename
    }
  }
`;
export const updateDeviceType = /* GraphQL */ `
  mutation UpdateDeviceType(
    $input: UpdateDeviceTypeInput!
    $condition: ModelDeviceTypeConditionInput
  ) {
    updateDeviceType(input: $input, condition: $condition) {
      id
      name
      producer
      website
      deveui
      appeui
      appkey
      description
      status
      createdAt
      updatedAt
      tag
      wlDeviceProfileID
      wlDeviceProfile {
        id
        name
        rf_region
        lora_class
        createdAt
        updatedAt
        owner
        __typename
      }
      wlServiceProfileID
      wlServiceProfile {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      destinationName
      destination {
        name
        expression
        expressionType
        decoder_name
        decoder_lang
        createdAt
        updatedAt
        owner
        __typename
      }
      creator
      is3gDeviceType
      dreiDeviceProfileId
      tbDeviceProfileId
      owner
      __typename
    }
  }
`;
export const deleteDeviceType = /* GraphQL */ `
  mutation DeleteDeviceType(
    $input: DeleteDeviceTypeInput!
    $condition: ModelDeviceTypeConditionInput
  ) {
    deleteDeviceType(input: $input, condition: $condition) {
      id
      name
      producer
      website
      deveui
      appeui
      appkey
      description
      status
      createdAt
      updatedAt
      tag
      wlDeviceProfileID
      wlDeviceProfile {
        id
        name
        rf_region
        lora_class
        createdAt
        updatedAt
        owner
        __typename
      }
      wlServiceProfileID
      wlServiceProfile {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      destinationName
      destination {
        name
        expression
        expressionType
        decoder_name
        decoder_lang
        createdAt
        updatedAt
        owner
        __typename
      }
      creator
      is3gDeviceType
      dreiDeviceProfileId
      tbDeviceProfileId
      owner
      __typename
    }
  }
`;
export const createWirelessDeviceProfiles = /* GraphQL */ `
  mutation CreateWirelessDeviceProfiles(
    $input: CreateWirelessDeviceProfilesInput!
    $condition: ModelWirelessDeviceProfilesConditionInput
  ) {
    createWirelessDeviceProfiles(input: $input, condition: $condition) {
      id
      name
      rf_region
      lora_class
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWirelessDeviceProfiles = /* GraphQL */ `
  mutation UpdateWirelessDeviceProfiles(
    $input: UpdateWirelessDeviceProfilesInput!
    $condition: ModelWirelessDeviceProfilesConditionInput
  ) {
    updateWirelessDeviceProfiles(input: $input, condition: $condition) {
      id
      name
      rf_region
      lora_class
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWirelessDeviceProfiles = /* GraphQL */ `
  mutation DeleteWirelessDeviceProfiles(
    $input: DeleteWirelessDeviceProfilesInput!
    $condition: ModelWirelessDeviceProfilesConditionInput
  ) {
    deleteWirelessDeviceProfiles(input: $input, condition: $condition) {
      id
      name
      rf_region
      lora_class
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWirelessServiceProfiles = /* GraphQL */ `
  mutation CreateWirelessServiceProfiles(
    $input: CreateWirelessServiceProfilesInput!
    $condition: ModelWirelessServiceProfilesConditionInput
  ) {
    createWirelessServiceProfiles(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWirelessServiceProfiles = /* GraphQL */ `
  mutation UpdateWirelessServiceProfiles(
    $input: UpdateWirelessServiceProfilesInput!
    $condition: ModelWirelessServiceProfilesConditionInput
  ) {
    updateWirelessServiceProfiles(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWirelessServiceProfiles = /* GraphQL */ `
  mutation DeleteWirelessServiceProfiles(
    $input: DeleteWirelessServiceProfilesInput!
    $condition: ModelWirelessServiceProfilesConditionInput
  ) {
    deleteWirelessServiceProfiles(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDestination = /* GraphQL */ `
  mutation CreateDestination(
    $input: CreateDestinationInput!
    $condition: ModelDestinationConditionInput
  ) {
    createDestination(input: $input, condition: $condition) {
      name
      expression
      expressionType
      decoder_name
      decoder_lang
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDestination = /* GraphQL */ `
  mutation UpdateDestination(
    $input: UpdateDestinationInput!
    $condition: ModelDestinationConditionInput
  ) {
    updateDestination(input: $input, condition: $condition) {
      name
      expression
      expressionType
      decoder_name
      decoder_lang
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDestination = /* GraphQL */ `
  mutation DeleteDestination(
    $input: DeleteDestinationInput!
    $condition: ModelDestinationConditionInput
  ) {
    deleteDestination(input: $input, condition: $condition) {
      name
      expression
      expressionType
      decoder_name
      decoder_lang
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLoRaWANGateway = /* GraphQL */ `
  mutation CreateLoRaWANGateway(
    $input: CreateLoRaWANGatewayInput!
    $condition: ModelLoRaWANGatewayConditionInput
  ) {
    createLoRaWANGateway(input: $input, condition: $condition) {
      id
      name
      producer
      website
      backhaulSimCard
      foto {
        bucket
        region
        key
        __typename
      }
      eui
      comments
      latitude
      longitude
      altitude
      country
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        background
        tag
        parentID
        creator
        customerID
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      wirelessGatewayId
      connstate {
        id
        state
        code
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      tag
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const updateLoRaWANGateway = /* GraphQL */ `
  mutation UpdateLoRaWANGateway(
    $input: UpdateLoRaWANGatewayInput!
    $condition: ModelLoRaWANGatewayConditionInput
  ) {
    updateLoRaWANGateway(input: $input, condition: $condition) {
      id
      name
      producer
      website
      backhaulSimCard
      foto {
        bucket
        region
        key
        __typename
      }
      eui
      comments
      latitude
      longitude
      altitude
      country
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        background
        tag
        parentID
        creator
        customerID
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      wirelessGatewayId
      connstate {
        id
        state
        code
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      tag
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const deleteLoRaWANGateway = /* GraphQL */ `
  mutation DeleteLoRaWANGateway(
    $input: DeleteLoRaWANGatewayInput!
    $condition: ModelLoRaWANGatewayConditionInput
  ) {
    deleteLoRaWANGateway(input: $input, condition: $condition) {
      id
      name
      producer
      website
      backhaulSimCard
      foto {
        bucket
        region
        key
        __typename
      }
      eui
      comments
      latitude
      longitude
      altitude
      country
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      objectID
      object {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        background
        tag
        parentID
        creator
        customerID
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      wirelessGatewayId
      connstate {
        id
        state
        code
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      tag
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const createGWConnState = /* GraphQL */ `
  mutation CreateGWConnState(
    $input: CreateGWConnStateInput!
    $condition: ModelGWConnStateConditionInput
  ) {
    createGWConnState(input: $input, condition: $condition) {
      id
      state
      code
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGWConnState = /* GraphQL */ `
  mutation UpdateGWConnState(
    $input: UpdateGWConnStateInput!
    $condition: ModelGWConnStateConditionInput
  ) {
    updateGWConnState(input: $input, condition: $condition) {
      id
      state
      code
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGWConnState = /* GraphQL */ `
  mutation DeleteGWConnState(
    $input: DeleteGWConnStateInput!
    $condition: ModelGWConnStateConditionInput
  ) {
    deleteGWConnState(input: $input, condition: $condition) {
      id
      state
      code
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createObject = /* GraphQL */ `
  mutation CreateObject(
    $input: CreateObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    createObject(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      description
      street
      zipcode
      city
      country
      latitude
      longitude
      altitude
      mount_description
      foto {
        bucket
        region
        key
        __typename
      }
      background
      tag
      parentID
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateObject = /* GraphQL */ `
  mutation UpdateObject(
    $input: UpdateObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    updateObject(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      description
      street
      zipcode
      city
      country
      latitude
      longitude
      altitude
      mount_description
      foto {
        bucket
        region
        key
        __typename
      }
      background
      tag
      parentID
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteObject = /* GraphQL */ `
  mutation DeleteObject(
    $input: DeleteObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    deleteObject(input: $input, condition: $condition) {
      id
      name
      type
      createdAt
      description
      street
      zipcode
      city
      country
      latitude
      longitude
      altitude
      mount_description
      foto {
        bucket
        region
        key
        __typename
      }
      background
      tag
      parentID
      creator
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      groupRead
      groupWrite
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      address
      zipcode
      country
      phone
      newsletter
      agb_dsgvo
      dashboard_template_id
      status
      role
      userpool_id
      username
      tb_userID
      createdAt
      updatedAt
      ttl
      owner
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      address
      zipcode
      country
      phone
      newsletter
      agb_dsgvo
      dashboard_template_id
      status
      role
      userpool_id
      username
      tb_userID
      createdAt
      updatedAt
      ttl
      owner
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      address
      zipcode
      country
      phone
      newsletter
      agb_dsgvo
      dashboard_template_id
      status
      role
      userpool_id
      username
      tb_userID
      createdAt
      updatedAt
      ttl
      owner
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      bmonc_id
      bmonc_customer_name
      atu
      iban
      bic
      address
      zipcode
      country
      phone
      email
      website
      type
      level
      tb_tenantID
      tb_ownerID
      tb_parent_customerID
      tb_customerID
      parent_customerID
      parent_customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      createdAt
      updatedAt
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      bmonc_id
      bmonc_customer_name
      atu
      iban
      bic
      address
      zipcode
      country
      phone
      email
      website
      type
      level
      tb_tenantID
      tb_ownerID
      tb_parent_customerID
      tb_customerID
      parent_customerID
      parent_customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      createdAt
      updatedAt
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      bmonc_id
      bmonc_customer_name
      atu
      iban
      bic
      address
      zipcode
      country
      phone
      email
      website
      type
      level
      tb_tenantID
      tb_ownerID
      tb_parent_customerID
      tb_customerID
      parent_customerID
      parent_customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
        __typename
      }
      createdAt
      updatedAt
      groupRead
      groupWrite
      owner
      __typename
    }
  }
`;
export const createDashboard = /* GraphQL */ `
  mutation CreateDashboard(
    $input: CreateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    createDashboard(input: $input, condition: $condition) {
      id
      name
      templateID
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const updateDashboard = /* GraphQL */ `
  mutation UpdateDashboard(
    $input: UpdateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    updateDashboard(input: $input, condition: $condition) {
      id
      name
      templateID
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const deleteDashboard = /* GraphQL */ `
  mutation DeleteDashboard(
    $input: DeleteDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    deleteDashboard(input: $input, condition: $condition) {
      id
      name
      templateID
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const createDashboardTemplate = /* GraphQL */ `
  mutation CreateDashboardTemplate(
    $input: CreateDashboardTemplateInput!
    $condition: ModelDashboardTemplateConditionInput
  ) {
    createDashboardTemplate(input: $input, condition: $condition) {
      id
      name
      description
      bucket
      key
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const updateDashboardTemplate = /* GraphQL */ `
  mutation UpdateDashboardTemplate(
    $input: UpdateDashboardTemplateInput!
    $condition: ModelDashboardTemplateConditionInput
  ) {
    updateDashboardTemplate(input: $input, condition: $condition) {
      id
      name
      description
      bucket
      key
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const deleteDashboardTemplate = /* GraphQL */ `
  mutation DeleteDashboardTemplate(
    $input: DeleteDashboardTemplateInput!
    $condition: ModelDashboardTemplateConditionInput
  ) {
    deleteDashboardTemplate(input: $input, condition: $condition) {
      id
      name
      description
      bucket
      key
      createdAt
      updatedAt
      groupRead
      groupWrite
      __typename
    }
  }
`;
export const createStatistics = /* GraphQL */ `
  mutation CreateStatistics(
    $input: CreateStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    createStatistics(input: $input, condition: $condition) {
      id
      customer
      customerType
      parentCustomerID
      nr_admins
      nr_users
      nr_subcustomerusers
      nr_sensors
      nr_gateways
      nr_cities
      nr_buildings
      nr_zones
      nr_rooms
      createdAt
      updatedAt
      groupRead
      owner
      __typename
    }
  }
`;
export const updateStatistics = /* GraphQL */ `
  mutation UpdateStatistics(
    $input: UpdateStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    updateStatistics(input: $input, condition: $condition) {
      id
      customer
      customerType
      parentCustomerID
      nr_admins
      nr_users
      nr_subcustomerusers
      nr_sensors
      nr_gateways
      nr_cities
      nr_buildings
      nr_zones
      nr_rooms
      createdAt
      updatedAt
      groupRead
      owner
      __typename
    }
  }
`;
export const deleteStatistics = /* GraphQL */ `
  mutation DeleteStatistics(
    $input: DeleteStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    deleteStatistics(input: $input, condition: $condition) {
      id
      customer
      customerType
      parentCustomerID
      nr_admins
      nr_users
      nr_subcustomerusers
      nr_sensors
      nr_gateways
      nr_cities
      nr_buildings
      nr_zones
      nr_rooms
      createdAt
      updatedAt
      groupRead
      owner
      __typename
    }
  }
`;
export const createSensor3gIndex = /* GraphQL */ `
  mutation CreateSensor3gIndex(
    $input: CreateSensor3gIndexInput!
    $condition: ModelSensor3gIndexConditionInput
  ) {
    createSensor3gIndex(input: $input, condition: $condition) {
      serialNr
      timestamp
      measEnd
      gsmSettings {
        acknowledgedByDevice
        syncMode
        measInt
        gsmSyncInt
        bleWakeUpInt
        bleWakeUpDuration
        __typename
      }
      status
      measImportStartDateTime
      measImportEndDateTime
      groupRead
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSensor3gIndex = /* GraphQL */ `
  mutation UpdateSensor3gIndex(
    $input: UpdateSensor3gIndexInput!
    $condition: ModelSensor3gIndexConditionInput
  ) {
    updateSensor3gIndex(input: $input, condition: $condition) {
      serialNr
      timestamp
      measEnd
      gsmSettings {
        acknowledgedByDevice
        syncMode
        measInt
        gsmSyncInt
        bleWakeUpInt
        bleWakeUpDuration
        __typename
      }
      status
      measImportStartDateTime
      measImportEndDateTime
      groupRead
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSensor3gIndex = /* GraphQL */ `
  mutation DeleteSensor3gIndex(
    $input: DeleteSensor3gIndexInput!
    $condition: ModelSensor3gIndexConditionInput
  ) {
    deleteSensor3gIndex(input: $input, condition: $condition) {
      serialNr
      timestamp
      measEnd
      gsmSettings {
        acknowledgedByDevice
        syncMode
        measInt
        gsmSyncInt
        bleWakeUpInt
        bleWakeUpDuration
        __typename
      }
      status
      measImportStartDateTime
      measImportEndDateTime
      groupRead
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
