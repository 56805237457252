import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Button as SelectBoxButton, SelectBox} from "devextreme-react/select-box";

function SaveChangesSelectBox(props) {

    const [value, setValue] = useState(props.value ? props.value : "");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const saveButton = {
        icon: 'save',
        type: 'default',
        visible: !isButtonDisabled,
        onClick:  (e) => {
            let result = e.validationGroup.validate();
            if (result.isValid) {
                props.onClick(props.buttonName, value);
                setIsButtonDisabled(true);
            }
        }
    };

    const handleValueChange = (event) => {
        setValue(event.value);
        setIsButtonDisabled(false);
    }

    return(
        <SelectBox
            name={props.buttonName+"tbox"}
            dataSource={props.dataSource}
            valueExpr="val"
            displayExpr="text"
            value={value}
            onValueChanged={handleValueChange}
            onKeyDown={() => setIsButtonDisabled(false)}>
            <SelectBoxButton
                name={props.buttonName}
                location="after"
                options={saveButton}
            />
        </SelectBox>
    );
}

SaveChangesSelectBox.propTypes = {
    dataSource: PropTypes.array,
    value: PropTypes.string,
    mask: PropTypes.string,
    buttonName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

export default SaveChangesSelectBox;