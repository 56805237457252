import React, {useContext} from "react";
import '../../App.css';
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import ErrorPage from "./ErrorPage";

function News() {
    const authContext = useContext(AuthContext);

    return (authContext?.authState === AuthState.SignedIn ?
            <div className="News">
                <Header link={"/"}/>
                <iframe className={"responsive-iframe"} src="https://www.bmonc.eu/bmoncnews/" title={"NewsFrame"}/>
            </div>
            :
            <ErrorPage/>
    )
}

export default News;
