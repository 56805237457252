import React, {useContext} from "react";
import '../../App.css';
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";


function Status() {
    const authContext = useContext(AuthContext);
    //const [t, i18n] = useTranslation();

    if (authContext.authState === AuthState.SignedIn) {
        return (
            <div className="Status">
                <Header />
                <iframe className={"responsive-iframe"} title={"StatusFrame"} src="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=BMONC&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTI1NDAwNjY4NTg4MCIsIlUiOiJ1cy1lYXN0LTFfcXJ6elMxbVF1IiwiQyI6Im50Ym43bGN0Yzc5bTlqOTNvcnB1cmtvdmQiLCJJIjoidXMtZWFzdC0xOmY2ZjI0ZTlhLWFjOTktNDE4ZC04MjI4LTUwZWJkNDlkNjRkOCIsIk0iOiJQdWJsaWMifQ%3D%3D"/>
            </div>
        );
    } else {
        return(null);
    }
}

export default Status;
