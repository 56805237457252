import React from "react";

function ErrorPage({text}) {
    return (
        <div className="ErrorPage">
            <h1>{ text ? text : "404: Some error happened"}</h1>
        </div>
    )
}

export default ErrorPage;
