import React, { useState, useContext, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import "../../App.css";
import PropTypes from "prop-types";
import { AmplifySignOut, AmplifyButton as Button } from "@aws-amplify/ui-react";
import AuthContext from "../../contexts/AuthContext";
import { AuthState } from "@aws-amplify/ui-components";
import { Popup } from "devextreme-react/popup";
import { TextBox } from "devextreme-react/text-box";
import SaveChangesTextBox from "./SaveChangesTextBox";
import notify from "devextreme/ui/notify";
import { API } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import SaveChangesSelectBox from "./SaveChangesSelectBox";
import { useTranslation } from "react-i18next";
import { Validator, PatternRule } from "devextreme-react/validator";
import { ScrollView } from "devextreme-react";
import { Link, useNavigate } from "react-router-dom";

function Header({ link, showSignOutButton }) {
  const authContext = useContext(AuthContext);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [userInfoFromDB, setUserInfoFromDB] = useState(null);
  const demo_ttl = Math.round(
    (authContext?.userInfoFromDB?.ttl * 1000 - new Date().getTime()) / 86400000
  );
  const [signOutButton, setSignOutButton] = useState(null);

  const element = signOutButton?.querySelector("amplify-button");
  if (element) {
    const button = element.querySelector("button");
    if (button) {
      button.style.borderRadius = "10px";
      button.style.backgroundColor = "rgb(194, 199, 207)";
      button.addEventListener("mouseover", () => {
        button.style.backgroundColor = "var(--bmonc-warning-color)";
        button.style.opacity = "1";
      });
      button.addEventListener("mouseout", () => {
        button.style.backgroundColor = "rgb(194, 199, 207)";
      });
    }
  }
  useEffect(() => {
    const shadowRoot = document.querySelector("amplify-sign-out")?.shadowRoot;
    if (shadowRoot) {
      setSignOutButton(shadowRoot);
    }
  }, [showSignOutButton]);

  useEffect(() => {
    setUserInfoFromDB(authContext.userInfoFromDB);
  }, [authContext.userInfoFromDB]);

  const showInfo = () => {
    setIsPopupVisible(true);
  };

  const hideInfo = () => {
    setIsPopupVisible(false);
  };

  const updateUserInfo = (name, value) => {
    //console.log(`${name} , ${value}`);
    let key = { id: userInfoFromDB.id };
    let user_data = { [name]: value };

    // then update user
    return API.graphql({
      query: updateUser,
      variables: { input: { ...key, ...user_data } },
    })
      .then(() => notify(t("header.updateUserInfo.success"), "success", 3000))
      .catch((err) => {
        notify(t("header.updateUserInfo.error"), "error", 3000);
        console.error(JSON.stringify(err));
      });
  };

  if (authContext?.authState === AuthState.SignedIn) {
    return (
      <>
        <div className="App-header">
          <div className={"App-logo"}>
            <Link to={"/"}>
              <img
                src={process.env.PUBLIC_URL + "/images/bmonc-logo.svg"}
                alt={"logo"}
              />
            </Link>
          </div>
          <div className={"App-right"}>
            <div
              id={"Avatar"}
              className={"Avatar"}
              onClick={() => {
                showInfo();
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/avatar.png"}
                alt={"avatar"}
              />
            </div>
            <div className={"UserInfo"}>
              <div className={"UserName"}>
                {userInfoFromDB
                  ? userInfoFromDB.firstname + " " + userInfoFromDB.lastname
                  : ""}
              </div>
              <div className={"UserType"}>
                {userInfoFromDB
                  ? userInfoFromDB.role +
                    (userInfoFromDB.role === "DEMO"
                      ? " (noch " + demo_ttl + " Tage)"
                      : "")
                  : ""}
              </div>
            </div>
            <div id={"HeaderButton"} className={"HeaderButton"}>
              {showSignOutButton && (
                <>
                  <AmplifySignOut
                    style={{
                      display: element ? "block" : "none",
                    }}
                  />
                  <Button
                    style={{
                      display: !element ? "block" : "none",
                    }}
                    className="signOutButton"
                  >
                    Sign out
                  </Button>
                </>
              )}
              {!showSignOutButton && (
                <Button
                  className="signOutButton"
                  onClick={() => navigate(link ? link : -1)}
                >
                  {"Zurück"}
                </Button>
              )}
            </div>
          </div>
        </div>
        <Popup
          className={"AboutMePopup"}
          visible={isPopupVisible}
          onHiding={hideInfo}
          title={t("header.aboutMePopupTitle")}
          closeOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          height={"auto"}
          width={"auto"}
          maxWidth={"800px"}
          maxHeight={"100vh"}
        >
          <div className="dx-fieldset"></div>
          {userInfoFromDB ? (
            <ScrollView id="scrollview">
              <div className="dx-fieldset">
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.role")}</div>
                  <div className="dx-field-value">
                    <TextBox
                      placeholder={t("global.role")}
                      readOnly={true}
                      value={userInfoFromDB.role}
                    ></TextBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.firstname")}</div>
                  <div className="dx-field-value">
                    <SaveChangesTextBox
                      buttonName="firstname"
                      placeholder={t("global.firstname")}
                      readOnly={false}
                      value={userInfoFromDB.firstname}
                      onClick={updateUserInfo}
                    />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.lastname")}</div>
                  <div className="dx-field-value">
                    <SaveChangesTextBox
                      buttonName="lastname"
                      placeholder={t("global.lastname")}
                      readOnly={false}
                      value={userInfoFromDB.lastname}
                      onClick={updateUserInfo}
                    />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.address")}</div>
                  <div className="dx-field-value">
                    <SaveChangesTextBox
                      buttonName="address"
                      placeholder={t("global.address")}
                      readOnly={false}
                      value={userInfoFromDB.address}
                      onClick={updateUserInfo}
                    />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.zipcode")}</div>
                  <div className="dx-field-value">
                    <SaveChangesTextBox
                      buttonName="zipcode"
                      placeholder={t("global.zipcode")}
                      readOnly={false}
                      value={userInfoFromDB.zipcode}
                      onClick={updateUserInfo}
                    />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.country")}</div>
                  <div className="dx-field-value">
                    <SaveChangesSelectBox
                      buttonName="country"
                      dataSource={t("countries:countries", {
                        returnObjects: true,
                      })}
                      readOnly={false}
                      value={userInfoFromDB.country}
                      onClick={updateUserInfo}
                    />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.phone")}</div>
                  <div className="dx-field-value">
                    <SaveChangesTextBox
                      buttonName="phone"
                      placeholder={"+43 12345678912"}
                      readOnly={false}
                      value={userInfoFromDB.phone}
                      onClick={updateUserInfo}
                    >
                      <Validator>
                        <PatternRule
                          message="Fehlerhafter Format"
                          pattern={"^[+]{1}[0-9]{6,12}$"}
                        />
                      </Validator>
                    </SaveChangesTextBox>
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t("global.email")}</div>
                  <div className="dx-field-value">
                    <TextBox
                      placeholder={t("global.email")}
                      readOnly={true}
                      value={userInfoFromDB.email}
                    ></TextBox>
                  </div>
                </div>
              </div>
              {userInfoFromDB.customer ? (
                <div className="dx-fieldset">
                  <div className="dx-fieldset-header">
                    {t("global.customer_information")}
                  </div>
                  {userInfoFromDB.customer.name ? (
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {t("global.customer-name")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder={t("global.customer-name")}
                          readOnly={true}
                          value={userInfoFromDB.customer.name}
                        ></TextBox>
                      </div>
                    </div>
                  ) : null}
                  {userInfoFromDB.customer.atu ? (
                    <div className="dx-field">
                      <div className="dx-field-label">{t("global.atu")}</div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder={t("global.atu")}
                          readOnly={true}
                          value={userInfoFromDB.customer.atu}
                        ></TextBox>
                      </div>
                    </div>
                  ) : null}
                  {userInfoFromDB.customer.bmonc_customer_name ? (
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {t("global.bmonc_customer_name")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder={t("global.bmonc_customer_name")}
                          readOnly={true}
                          value={userInfoFromDB.customer.bmonc_customer_name}
                        ></TextBox>
                      </div>
                    </div>
                  ) : null}
                  {userInfoFromDB.customer.bmonc_id ? (
                    <div className="dx-field">
                      <div className="dx-field-label">
                        {t("global.bmonc_id")}
                      </div>
                      <div className="dx-field-value">
                        <TextBox
                          placeholder={t("global.bmonc_id")}
                          readOnly={true}
                          value={userInfoFromDB.customer.bmonc_id}
                        ></TextBox>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </ScrollView>
          ) : null}
        </Popup>
      </>
    );
  } else {
    return null;
  }
}

Header.propTypes = {
  link: PropTypes.string,
  showSignOutButton: PropTypes.bool,
};

export default Header;
