import React, {useContext} from "react";
import '../../App.css';
import Tile from "../Tile";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {useTranslation} from "react-i18next";
import ErrorPage from "./ErrorPage";
import {useNavigate} from "react-router-dom";


function Customers() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();

    if (authContext.authState === AuthState.SignedIn) {
        return (
            <div className="Customers">
                <Header link={"/"}/>
                <div className={"Tiles"}>
                    { (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                        <Tile onClick={() => navigate("/customers-list")} text={"Kunden verwalten"}/>
                        : null
                    }
                    { (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                        <Tile onClick={() => navigate("/users-list")} text={"Benutzer verwalten"}/>
                        : null
                    }
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default Customers;
