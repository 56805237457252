import {API} from "aws-amplify";
import notify from "devextreme/ui/notify";
import {
    getCustomer, listCustomers
} from "../graphql/queries";

export const customersStore = {
    key: 'id',
    load: async (loadOptions) => {
        let {sorting} = loadOptions;
        let customer = [];
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data: {listCustomers: {items: page, nextToken}}} = await API.graphql({
                query: listCustomers,
                variable: {
                    nextToken: nt},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            customer = customer.concat(page);
        } while (nt !== null)
        customer.sort((a, b) => a[sorting]?.localeCompare(b[sorting]))
        return {
            data: customer
        };
    },
    byKey: (key) => {
        return API.graphql({
            query: getCustomer,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getCustomer: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No Customer with Key ${key} found`, "error", 3000);
            });
    }
};