import React, {useContext, useEffect, useState} from "react";
import '../../App.css';
import TreeList, { HeaderFilter, Column} from 'devextreme-react/tree-list';
import {API} from "aws-amplify";
import {listStatistics} from "../../graphql/queries";
import {LoadPanel, SpeedDialAction} from "devextreme-react";
import {downloadCSVFile} from "../../settings/functions";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import ErrorPage from "../Pages/ErrorPage";
import {useTranslation} from "react-i18next";

function StatisticsList(){
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const [statistics, setStatistics] = useState([]);

    // Fetches all Statistics
    const fetchStatistics = async () => {
        try {
            let res = [];
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data : {listStatistics: {items: page, nextToken}}} = await API.graphql({
                    query: listStatistics,
                    variables: {nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
            setStatistics(res);
        } catch (err) { console.error(err) }
    }

    const usersToCSV = async () => {
        let order = ["customer", "nr_sensors", "nr_gateways", "nr_admins", "nr_users", "nr_subcustomerusers", "nr_cities", "nr_buildings", "nr_zones", "nr_rooms"];
        let names = ["Kunde", "Sensoren", "Gateways", "Admins", "User", "Subcustomer Users", "Städte", "Gebäude", "Zonen", "Räume"];
        let separator = ";";
        try {
            let statsList = [];
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data : {listStatistics: {items: page, nextToken}}} = await API.graphql({
                    query: listStatistics,
                    variables: {nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                statsList = statsList.concat(page);
            } while (nt !== null);

            let rows = []
            rows.push(names.join(separator));
            // row wise
            statsList.forEach((row) => {
                // column wise
                let columns = [];
                order.forEach((col_name) => {
                    columns.push(row[col_name]);
                });
                rows.push(columns.join(separator));
            });
            downloadCSVFile(rows.join('\n'), "report-"+(new Date().getTime())+".csv");
        } catch (err) { console.error(err) }
    }

    useEffect(() => {
        fetchStatistics();
    }, []);

    if (authContext?.accesslevel === 1 || authContext?.accesslevel === 2) {
        return (
            <div className="CustomersList">
                <Header />
                <div className={"Content full-width"}>
                    <h2 className={"headline"}>Report</h2>
                    <TreeList
                        dataSource={statistics}
                        showBorders={true}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        keyExpr="id"
                        parentIdExpr="parentCustomerID"
                        id="statistics"
                    >
                        {/*<ColumnChooser enabled={true} mode={"select"}/>*/}
                        <LoadPanel enabled/>
                        <HeaderFilter visible={true}/>
                        <Column dataField="customer" caption={"Kunde"} allowSorting={true}/>
                        <Column dataField="nr_sensors" caption={"Sensoren"}/>
                        <Column dataField="nr_gateways" caption={"Gateways"}/>
                        <Column dataField="nr_admins" caption={"Admins"}/>
                        <Column dataField="nr_users" caption={"User"}/>
                        <Column dataField="nr_subcustomerusers" caption={"Subcustomer Users"}/>
                        <Column dataField="nr_cities" caption={"Städte"}/>
                        <Column dataField="nr_buildings" caption={"Gebäude"}/>
                        <Column dataField="nr_zones" caption={"Zonen"}/>
                        <Column dataField="nr_rooms" caption={"Räume"}/>
                        <SpeedDialAction
                            icon="file"
                            label="CSV exportieren"
                            index={2}
                            elementAttr={{class: "csvExportButton"}}
                            onClick={usersToCSV}
                        />
                    </TreeList>
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default StatisticsList;
