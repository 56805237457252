import {Geo} from "aws-amplify";
import {parseAlpha3ToInternalCountryCode} from "./helpers";

const mapGeoData = async (geo) => {
    const [longitude, latitude] = geo.geometry.point;
    let street = geo.street ? geo.street : null;
    if (geo.addressNumber) {
        if (street) {
            street+= ' ';
        }
        street += geo.addressNumber;
    }
    return {
        longitude: longitude,
        latitude: latitude,
        street: street,
        zipcode: geo.postalCode ? geo.postalCode: '',
        city: geo.municipality ? geo.municipality : '',
        country: parseAlpha3ToInternalCountryCode(geo.country),
    };
};

export const geoLocationStore = {
    key: 'id',
    load: async (loadOptions) => {
        const searchText = loadOptions.searchValue ? loadOptions.searchValue : '';
        return Geo.searchByText(searchText, {
            countries: ['AUT', 'DEU']
        })
            .then(async results => {
                return await Promise.all(
                    results.map((geo) => mapGeoData(geo))
                );
            });
    }
};