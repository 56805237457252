import React, {useContext, useEffect, useState} from "react";
import '../../App.css';
import TreeList, {
    HeaderFilter, SearchPanel, Editing, Toolbar, Item as TItem,
    Popup, LoadPanel, Sorting,
    Form, Column, Lookup, ColumnChooser, Paging, Scrolling
} from 'devextreme-react/tree-list';
import {
    Item, GroupItem, RequiredRule, AsyncRule, PatternRule, EmptyItem
} from 'devextreme-react/form';
import {API} from "aws-amplify";
import {
    CompanybyBmoncID,
    listCustomers,
    listUsers,
    UserByEmail
} from "../../graphql/queries";
import {createUser, deleteCustomer, deleteUser, updateUser} from "../../graphql/mutations";
import {SpeedDialAction} from "devextreme-react";
import {downloadCSVFile} from "../../settings/functions";
import Header from "../Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import notify from "devextreme/ui/notify";
import "devextreme-react/select-box";
import SelectBox from "devextreme-react/select-box";
import {Pager, StateStoring} from "devextreme-react/data-grid";
import {MASK_Phone, REGEXP_Email, REGEXP_Phone} from "../../settings/validation";
import ErrorPage from "../Pages/ErrorPage";
import {useTranslation} from "react-i18next";
import {dashboardTemplatesStore} from "../../common/dashboardTemplatesStore";

const editing_mode = "popup";
const states = ['UNCONFIRMED', 'CONFIRMED', 'REQUESTED', 'APPROVED', 'BLOCKED'];
const statuses = [{id:'UNCONFIRMED', name:'UNCONFIRMED'}, {id:'CONFIRMED', name:'CONFIRMED'}, {id:'REQUESTED', name:'REQUESTED'}, {id:'APPROVED', name:'APPROVED'}, {id:'BLOCKED', name:'BLOCKED'}];
const roles = [{id:'DEMO', name:'DEMO'}, {id:'ADMIN', name:'ADMIN'}, {id:'USER', name:'USER'}, {id:'SUBCUSTOMERUSER', name:'SUBUSER'}];
const filteringValues = [
    {
        value: [],
        text: 'Alle',
    },
    {
        value: [['role', '=', 'DEMO']],
        text: 'DEMO Benutzer',
    }, {
        value: [['status', '=', 'REQUESTED']],
        text: 'Offenen Kundenanfragen',
    }];

const checkEmailAlreadyExists = async (params) => {
    const allowEditing = params?.formItem?.column?.allowEditing;
    if (!allowEditing) {
        return true;
    }
    let email = params.value;
    let not_exists= false;
    try {
        const {data : {UserByEmail: {items: page}}} = await API.graphql({
            query: UserByEmail,
            variables: {email: email},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        if (page.length===0){
            not_exists = true;
        }
    } catch (err) { console.error(err) }
    return not_exists;
}

const allowInserting = (e) => e.row.data.node_type === 'CUSTOMER';

// Fetches all Users
const fetchUsers = async () => {
    let users = [];
    try {
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data : {listUsers: {items: page, nextToken}}} = await API.graphql({
                query: listUsers,
                variables: {nextToken: nt, filter: {not: {role: {eq: 'MASTER'}}}}, // filter: {or: [{type: {eq: 'ADMIN'}},{type: {eq: 'USER'}}]}
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            users = users.concat(page);
        } while (nt !== null)
        const {data : {CompanybyBmoncID: {items: kubus_customers}}} = await API.graphql({
            query: CompanybyBmoncID,
            variables: {bmonc_id: 'Kubus'},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        let kubus_customer = kubus_customers[0];
        // change the BMONC Customer name for all empty names to Kubus
        users.forEach((elem, idx) => {
            let parent = 'Kubus';
            if (elem.customer) {
                if (elem.customer['bmonc_customer_name']) {
                    parent = elem.customer['bmonc_customer_name'];
                }
            } else {
                users[idx].customer = {
                    bmonc_customer_name: kubus_customer.bmonc_customer_name,
                    bmonc_id: kubus_customer.bmonc_id
                }
                users[idx].customerID = kubus_customer.id
            }
            users[idx].parent = parent;
        });
    } catch (err) { console.error(err) }
    return users;
}

// Fetches all Customers
const fetchCustomers = async () => {
    let customers = [];
    try {

        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data : {listCustomers: {items: page, nextToken}}} = await API.graphql({
                query: listCustomers,
                variables: {nextToken: nt},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            customers = customers.concat(page);
        } while (nt !== null)
    } catch (err) { console.error(err) }
    return customers;
}

function UsersTreeList() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const [filtering, setFiltering] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [users_tree, setUsersTree] = useState([]);

    useEffect(() => {
        Promise.all([fetchCustomers(), fetchUsers()]).then(
            ([customers, users]) => {
                setCustomers(customers);
                constructCustomerUserTree(customers, users);
            })
    }, []);

    const filterChanged = (e) => setFiltering(e.value)

    const getFilteredCustomers = () => {
        let filter = ['bmonc_customer_name', '<>', null];
        return {
            store: customers,
            filter:filter
        };
    }

    const getFilteredStatus = (options) => {
        let filter = null;
        if (options.data) {
            if (options.data.status === 'REQUESTED' || options.data.status === 'APPROVED' || options.data.status === 'BLOCKED') {
                filter = [['id', '=', 'APPROVED'],'or',['id', '=', 'BLOCKED']];
            } else if (options.data.status === 'CONFIRMED') {
                filter = [['id', '=', 'REQUESTED'],'or',['id', '=', 'APPROVED'],'or',['id', '=', 'BLOCKED']];
            } else {
                filter = ['id', '<>', 'UNCONFIRMED'];
            }
        }
        return {
            store: statuses,
            filter:filter
        };
    }

    const getFilteredRoles = (options) => {
        let filter = ['id', '<>', 'DEMO'];
        if (options.data) {
            if (options.data.role === 'DEMO' || options.data.role === 'ADMIN' || options.data.role === 'USER') {
                filter = [['id', '=', 'ADMIN'], 'or', ['id', '=', 'USER']];
            } else if (options.data.role === 'SUBCUSTOMERUSER') {
                filter = ['id', '=', 'SUBCUSTOMERUSER'];
            }
        }
        return {
            store: roles,
            filter:filter
        };
    }

    const onEditingStart = (event) => {
        const assignDemoUserToExistingCustomer = event.data.customer ? (!event.data.customer.bmonc_id && !event.data.customer.bmonc_customer_name) : true;
        if (authContext?.accesslevel === 1) {
            if (!assignDemoUserToExistingCustomer) {
                event.component.columnOption("customer.bmonc_id", "formItem", {visible: true});
                event.component.columnOption("customer.bmonc_id", "visible", true);
                event.component.columnOption("customer.bmonc_customer_name", "formItem", {visible: true});
                event.component.columnOption("customer.bmonc_customer_name", "visible", true);
            }
        }
        if (assignDemoUserToExistingCustomer) {
            event.component.columnOption("customerID", "formItem", {visible: true});
            event.component.columnOption("customerID", "visible", true);
        }
        // DEMO and REQUESTED
        if (event.data.role === 'DEMO' && event.data.status === 'REQUESTED') {
            // for the check in onEditorPreparing
            event.data.customerApproving=true;
        }
        event.component.columnOption("email", "allowEditing", false);
    }

    const onEditorPreparing = (event) => {
        if (event.row?.data?.customerApproving) {
            if (event.dataField === "role") {
                if (event.editorOptions.value === 'DEMO') {
                    event.editorOptions.value = "USER";
                    event.setValue(event.editorOptions.value);
                }
            }
            if (event.dataField === "status") {
                if (event.editorOptions.value === 'REQUESTED') {
                    event.editorOptions.value = "APPROVED";
                    event.setValue(event.editorOptions.value);
                }
            }
            if (event.dataField === "dashboard_template_id") {
                event.editorOptions.value = "DEFAULT";
                event.setValue(event.editorOptions.value);
            }
        }
    }

    const onInitNewRow = (e) => {
        const parentId = e.data.parentId;
        if (parentId && parentId !== -1) {
            e.data.customerID = parentId;
            e.component.columnOption("customerID", "allowEditing", false);
        } else {
            e.component.columnOption("customerID", "allowEditing", true);
        }
        // set default dashboard
        e.data.dashboard_template_id='DEFAULT';
        e.data.role='USER';
        e.data.status='APPROVED';
        e.component.columnOption("email", "allowEditing", true);
        e.component.columnOption("customerID", "formItem", {visible: true});
        e.component.columnOption("customerID", "visible", true);
        e.component.columnOption("status", "formItem", {visible: false});
        e.component.columnOption("status", "visible", false);
        e.component.columnOption("customer.bmonc_id", "formItem", {visible: false});
        e.component.columnOption("customer.bmonc_customer_name", "formItem", {visible: false});
    }

    const constructCustomerUserTree = async (customers, users) => {
        let usersTreeTmp = [];
        // Start building the tree with customerAccounts
        for (const customer of customers) {
            usersTreeTmp.push({
                ...customer,
                parentId: customer.parent_customerID ? customer.parent_customerID : -1,
                customer : {
                    bmonc_customer_name : customer.bmonc_customer_name,
                    bmonc_id : customer.bmonc_id
                },
                node_type: 'CUSTOMER'
            });
        }
        // Iterate over all facilities of the customerAccount
        for (const user of users) {
            const treeNode = {
                ...user,
                parentId: user.customerID ? user.customerID : -2,
                node_type: 'USER'
            };
            usersTreeTmp.push(treeNode);
        }
        setUsersTree(usersTreeTmp);
    }

    const addUser = (event) => {
        // important to clean this elem because it is not in the schema
        delete event.data.parentId;
        let u = authContext?.userInfoFromDB;
        if (!u) {
            alert("The user could not be found by email nor username");
            event.cancel = true;
            return;
        }
        let customer = customers.find((elem) => elem.id === event.data.customerID);
        if ((customer.level === 'CUSTOMER' && event.data.role === 'SUBCUSTOMERUSER') ||
            (customer.level === 'SUBCUSTOMER' && event.data.role !== 'SUBCUSTOMERUSER')){
            alert("Die von Ihnen gewählte Zuordnung ist ungültig. SUBCUSTOMER können nur Sub-Kunden zugeordnet werden. ADMIN und USER können nur Kunden zugeordnet werden.");
            event.cancel = true;
            return;
        }
        let additional_info = {
            id: event.data.email,
            userpool_id: u.userpool_id,
            status: 'APPROVED',
            createdAt: (new Date()).toISOString(),
            updatedAt: (new Date()).toISOString(),
        };
        const item = {...additional_info, ...event.data}
        return API.graphql({query: createUser, variables: {input: item}}).then(() => {
            notify("Der neue Benutzer wurde angelegt.", "success", 3000);
            return fetchUsers().then((users) => constructCustomerUserTree(customers, users));
        }).catch((e) => {
            alert(JSON.stringify(e));
            console.error(e);
            e.cancel = true;
        });
    }

    const editUser = async (event) => {
        // important to clean this elem because it is not in the schema
        let key = {id: event.key};
        let delete_old_customer = false;
        if (event.oldData.role === 'DEMO') { // only for DEMO User because the customer switch after that Step is not implemented
            if (event.oldData.customer) {
                if ((!event.oldData.customer.bmonc_id || !event.oldData.customer.bmonc_customer_name) && !event.newData.customerID) {
                    notify("Please finish first the customer setup in \"Kunden verwalten\" or choose a customer.", "warning", 3000);
                    event.cancel = true;
                    return;
                } else if (event.newData.customerID) {// user has been assigned to a different customer -> clean old customer on succedssful user update
                    delete_old_customer = true;
                }
            } else {
                if (!event.newData.customerID) { // prevent the action if customer has not even set previously
                    notify("Please choose a customer for the DEMO User.", "warning", 3000);
                    event.cancel = true;
                    return;
                } else { // user has been assigned to a different customer -> clean old customer on succedssful user update
                    delete_old_customer = true;
                }
            }
        }

        if ('customer' in event.newData && 'role' in event.newData) { // check if customer assignment is allowed
            let customer = customers.find((elem) => elem.id === event.newData.customerID);
            if ((customer.level === 'CUSTOMER' && event.newData.role === 'SUBCUSTOMERUSER') ||
                (customer.level === 'SUBCUSTOMER' && event.newData.role !== 'SUBCUSTOMERUSER')) {
                alert("Die von Ihnen gewählte Zuordnung ist ungültig. SUBCUSTOMER können nur Sub-Kunden zugeordnet werden. ADMIN und USER können nur Kunden zugeordnet werden.");
                event.cancel = true;
                return;
            }
        }
        // split user and customer data
        let user_data = {...event.newData};
        delete user_data.customer;

        // check the status
        if ('status' in event.newData) {
            if ((states.indexOf(event.newData.status) < states.indexOf(event.oldData.status)) &&
                !(event.oldData.status === 'BLOCKED' && event.newData.status === 'APPROVED')) {
                notify("The allowed User status transitions are: UNCONFIRMED -> CONFIRMED -> REQUESTED -> APPROVED -> BLOCKED ; BLOCKED -> APPROVED", "error", 3000);
                event.cancel = true;
                return;
            }
            if (event.newData.status === 'APPROVED' && event.newData.role === 'DEMO') {
                notify("Please change the user type to either ADMIN, USER, or SUBCUSTOMERUSER for approving", "warning", 3000);
                event.cancel = true;
                return;
            }
        }
        // update user
        try {
            await API.graphql({query: updateUser, variables: {input: {...key, ...user_data}}});
            notify("Benutzer " + event.key + " bearbeitet", "success", 3000);
        } catch (err) {
            console.error(JSON.stringify(err));
        }
        // delete old customer to keep ddb clean
        if (delete_old_customer) {
            try {
                if (event.oldData.customer) {
                    let c_key = {id: event.oldData.customer.id}
                    await API.graphql({query: deleteCustomer, variables: {input: {...c_key}}});
                    //console.log("Customer"+ event.oldData.customerID + " deleted");
                }
            } catch (err) {
                console.error(JSON.stringify(err));
            }
        }
    }

    const removeUser = async (event) => {
        if (authContext?.accesslevel !== 1 && authContext?.accesslevel !== 2) {
            alert("You do not have the rights for this action");
            event.cancel = true;
        } else if (authContext?.accesslevel === 2 && event.data.type === 'ADMIN') {
            alert("You do not have the rights to delete an ADMIN");
            event.cancel = true;
        } else {
            let key = {id: event.key};
            return API.graphql({query: deleteUser, variables: {input: key}}).then((e) => {
                notify(event.key + " deleted", "success", 3000);
            }).catch((e) => {
                alert(JSON.stringify(e));
                console.error(e);
                event.cancel = true;
            });
        }
    }

    const usersToCSV = async () => {
        let order = ["firstname", "lastname", "status", "role", "country", "address", "email", "zipcode", "createdAt"]
        let header = ["BMONC-ID", "BMONC-Kundenname", "Unterkunde", "Vorname", "Nachname", "Status", "Rolle","Land", "Adresse", "E-mail", "PLZ", "Erstellungsdatum"]
        let separator = ";";
        try {
            let userslist = users_tree.slice(); // copy array

            const customers = userslist.filter(c => (c.node_type === 'CUSTOMER'));
            const users = userslist.filter(c => (c.node_type === 'USER'));
            const top_level_customers = customers.filter(c => (c.parentId === -1));

            top_level_customers.sort((a,b) => a?.bmonc_customer_name?.localeCompare(b?.bmonc_customer_name));

            let rows = []
            // add header
            rows.push(header.join(separator));

            // for each top level customer
            top_level_customers.forEach((tlc) => {
                // entry for tlc
                let columns = [tlc["bmonc_id"], tlc["bmonc_customer_name"], ""];
                order.forEach((col_name) => {
                    columns.push(tlc[col_name]);
                });
                rows.push(columns.join(separator));


                // for each user of tlc
                users.filter(c => (c.customerID === tlc.id)).forEach(u => {
                    let columns = ["", "", ""]; // shift by one column
                    order.forEach((col_name) => {
                        columns.push(u[col_name]);
                    });
                    rows.push(columns.join(separator));
                });

                // for each subcustomer of tlc
                const sub_level_customers = customers.filter(c => (c.parentId === tlc.id));
                sub_level_customers.sort((a,b) => a.bmonc_customer_name.localeCompare(b.bmonc_customer_name));
                sub_level_customers.forEach((slc) => {
                    // entry for slc
                    let columns = ["", "", slc["bmonc_customer_name"]];
                    order.forEach((col_name) => {
                        columns.push(slc[col_name]);
                    });
                    rows.push(columns.join(separator));

                    // for each user of slc
                    users.filter(c => (c.customerID === slc.id)).forEach(u => {
                        let columns = ["", "", ""]; // shift by one column
                        order.forEach((col_name) => {
                            columns.push(u[col_name]);
                        });
                        rows.push(columns.join(separator));
                    });
                })
            })
            downloadCSVFile(rows.join('\n'), "userslist-"+(new Date().getTime())+".csv");
        } catch (err) { console.error(err) }
    }

    if (authContext?.authState === AuthState.SignedIn && (authContext?.accesslevel === 1 || authContext?.accesslevel === 2)) {
        return (
            <div className="UsersTreeList">
                <Header />
                <div className={"Content full-width"}>
                    <h2 className={"headline"}>{t("userstreelist.headline")}</h2>
                    <TreeList
                        dataSource={users_tree}
                        defaultFilterValue={[]}
                        filterValue={filtering}
                        showBorders={true}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        rootValue={-1}
                        keyExpr="id"
                        parentIdExpr="parentId"
                        id="users"
                        onRowInserting={addUser}
                        onRowUpdating={editUser}
                        onInitNewRow={onInitNewRow}
                        onEditingStart={onEditingStart}
                        onEditorPreparing={onEditorPreparing}
                        onRowRemoving={removeUser}
                    >
                        <Sorting
                            mode="multiple" />
                        <Scrolling
                            mode="standard" />
                        <Paging
                            enabled={true}
                            defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 25, 50, 'all']}
                            showInfo={true}/>
                        <ColumnChooser enabled={true} mode={"select"}/>
                        <StateStoring
                            enabled={true}
                            type="localStorage"
                            storageKey="userstreelist"
                        />
                        <LoadPanel enabled/>
                        <SearchPanel visible={true} width={"auto"}/>
                        <HeaderFilter visible={true}/>
                        <Toolbar>
                            {(authContext?.accesslevel === 1 ? <TItem location="before" locateInMenu={"auto"}>
                                <SelectBox
                                    width="225"
                                    items={filteringValues}
                                    displayExpr="text"
                                    valueExpr="value"
                                    value={filtering}
                                    onValueChanged={filterChanged} />
                            </TItem> : null)}
                            <TItem name="addRowButton" location="after"/>
                            <TItem
                                name="columnChooserButton"
                                locateInMenu={"auto"}
                                location="after"
                            />
                            <TItem name="searchPanel" location="after" locateInMenu={"auto"}/>
                        </Toolbar>

                        {(authContext?.accesslevel === 1) ?
                            <Editing
                                mode={editing_mode}
                                useIcons={true}
                                allowAdding={allowInserting}
                                allowUpdating={(e) => e.row.data.node_type === 'USER'}
                                allowDeleting={(e) => e.row.data?.node_type === 'USER' && e.row.data?.customer?.bmonc_id}
                            >
                                <Popup showTitle={false} height={"auto"} maxWidth={"1000px"} maxHeight={"100vh"}/>
                                    {(authContext?.accesslevel === 1) ?
                                    <Form colCount={2}>
                                        <GroupItem caption={t("global.user_data")} colSpan={1}>
                                            <Item dataField="firstname" colSpan={1}>
                                                <RequiredRule/>
                                            </Item>
                                            <Item dataField="lastname" colSpan={1}>
                                                <RequiredRule/>
                                            </Item>
                                            <Item dataField={"dashboard_template_id"} colSpan={1}>
                                                <RequiredRule/>
                                            </Item>
                                            <Item dataField={"role"} colSpan={1}>
                                                <RequiredRule/>
                                            </Item>
                                            <Item dataField={"status"} colSpan={1}>
                                                <RequiredRule/>
                                            </Item>
                                            <Item dataField="customerID">
                                                <RequiredRule/>
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption={t("global.contact")} colSpan={1}>
                                            <Item dataField="address" colSpan={1}/>
                                            <Item dataField="zipcode" colSpan={1}/>
                                            <Item dataField="country"/>
                                            <Item dataField="phone" editorType="dxTextBox"
                                                  editorOptions={MASK_Phone} colSpan={1}>
                                                <PatternRule message="Fehlerhafter Format"
                                                             pattern={REGEXP_Phone}/>
                                            </Item>
                                            <Item dataField="email" colSpan={1}>
                                                <RequiredRule/>
                                                <AsyncRule
                                                    message="This Email is already registered or invalid"
                                                    validationCallback={checkEmailAlreadyExists}
                                                    reevaluate={false}
                                                />
                                                <PatternRule message="Fehlerhafter Format"
                                                             pattern={REGEXP_Email}/>
                                            </Item>
                                        </GroupItem>
                                        <Item dataField="customer.bmonc_id"/>
                                        <EmptyItem/>
                                        <Item dataField="customer.bmonc_customer_name"/>
                                        <EmptyItem/>
                                    </Form>
                                    :
                                    <Form colCount={1}>
                                        <Item dataField={"role"}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="firstname" colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="lastname" colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="address" colSpan={1}/>
                                        <Item dataField="zipcode" colSpan={1}/>
                                        <Item dataField="country"/>
                                        <Item dataField="phone" editorType="dxTextBox"
                                              editorOptions={MASK_Phone} colSpan={1}>
                                            <PatternRule message="Fehlerhafter Format" pattern={REGEXP_Phone}/>
                                        </Item>
                                        <Item dataField={"status"} colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="email" colSpan={1}>
                                            <RequiredRule/>
                                            <PatternRule message="Fehlerhafter Format"
                                                         pattern={REGEXP_Email}/>
                                        </Item>
                                        <Item dataField="customerID"/>
                                    </Form>
                                }
                            </Editing>
                            :
                            null
                        }
                        <SpeedDialAction
                            icon="file"
                            label="CSV exportieren"
                            index={2}
                            elementAttr={{class: "csvExportButton"}}
                            onClick={() => {
                                usersToCSV();
                            }}
                        />
                        {(authContext?.accesslevel === 1) ? <Column dataField="customer.bmonc_customer_name" allowEditing={false} caption={t("global.bmonc_customer_name")} defaultSortIndex={0} defaultSortOrder="asc" visible={true}/>:null}
                        {(authContext?.accesslevel === 1) ? <Column dataField="customer.bmonc_id" caption={t("global.bmonc_id")} allowEditing={false} visible={true}/>:null}
                        {(authContext?.accesslevel === 2) ? <Column dataField="name" allowEditing={true} caption={t("global.name")} />:null}
                        <Column dataField="firstname" caption={t("global.firstname")}/>
                        <Column dataField="lastname" caption={t("global.lastname")}/>
                        <Column dataField="status" caption={t("global.status")} formItem={{visible: true}}>
                            <Lookup dataSource={getFilteredStatus} valueExpr="id" displayExpr="name"/>
                        </Column>
                        <Column dataField="role" caption={t("global.role")}>
                            <Lookup dataSource={getFilteredRoles} valueExpr="id" displayExpr="name"/>
                        </Column>
                        <Column dataField="country" caption={t("global.country")}>
                            <Lookup dataSource={t('countries:countries',{ returnObjects: true })} valueExpr="val" displayExpr="text"/>
                        </Column>
                        <Column dataField="address" caption={t("global.address")}/>
                        <Column dataField="email" caption={t("global.email")}/>
                        <Column dataField="zipcode" caption={t("global.zipcode")}/>
                        <Column dataField="customerID" caption={t("global.relation")} visible={false}>
                            <Lookup dataSource={getFilteredCustomers} valueExpr="id" displayExpr={(authContext?.accesslevel === 1 ? "bmonc_customer_name": "name")} searchEnabled={true}/>
                        </Column>
                        {(authContext?.accesslevel === 1) ?
                            <Column dataField="dashboard_template_id" caption={t("global.default-dashboard")}>
                                <Lookup dataSource={dashboardTemplatesStore} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                            </Column>
                            :null
                        }
                        {(authContext?.accesslevel === 1) ? <Column dataField={"createdAt"} allowEditing={false} caption={t("global.creationdate")} dataType="datetime"></Column>:null}
                        <Column dataField="phone" caption={t("global.phone")}/>
                        <Column dataField="customer.parent_customer.bmonc_id" allowEditing={false} caption={t("global.parent_customer.bmonc_id")} visible={false}/>
                        <Column dataField="customer.parent_customer.bmonc_customer_name" allowEditing={false} caption={t("global.parent_customer.bmonc_customer_name")} visible={false}/>
                    </TreeList>
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default UsersTreeList;
