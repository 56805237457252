import 'devextreme/dist/css/dx.light.css';
import '../../App.css';
import React, {useContext, useState, useEffect} from "react";
import DataGrid, {
    Column,
    Editing,
    Pager,
    Paging,
    Popup,
    FilterRow,
    ColumnChooser,
    Form,
    Lookup, SearchPanel, Button, StateStoring
} from 'devextreme-react/data-grid';
import {Item, GroupItem, Label} from 'devextreme-react/form';
import {API} from "aws-amplify";
import {deleteDevices, updateDevices, updateSensor3gIndex} from "../../graphql/mutations";
import {LoadPanel} from "devextreme-react";
import AuthContext from "../../contexts/AuthContext";
import {AuthState} from "@aws-amplify/ui-components";
import {getDevices} from "../../graphql/queries";
import Header from "../Header/Header";
import {NumericRule} from "devextreme-react/validator";
import {useTranslation} from "react-i18next";
import notify from "devextreme/ui/notify";
import {Item as TItem, Scrolling, Toolbar} from "devextreme-react/tree-list";
import { CheckBox } from 'devextreme-react/check-box';
import {listDevicesForAdmins, listDevicesForNotAdmins, listObjectsMinimal} from "../../graphql/customQueries";
import ErrorPage from "../Pages/ErrorPage";
import {useNavigate} from "react-router-dom";
import {startPolling, timeout} from "../../common/helpers";
import {customersStore} from "../../common/customersStore";

function DevicesList() {
    const authContext = useContext(AuthContext);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [devices, setDevices] = useState(null);
    const [objects, setObjects] = useState([]);

    const sensor3gIndexStatusEditorRender = (cell) => {
        const value = cell.value === "STOPPED" ? false : true;
        return (
            <CheckBox
                iconSize={30}
                disabled={![1,2].includes(authContext?.accesslevel)}
                defaultValue={value}
                onValueChanged={(e) => cell.setValue(e.value === false ? "STOPPED" : "ACTIVE")}
            />
        );
    };

    // Fetches all devices for the customer
    const fetchDevices = async (query) => {
        try {
            let res = [];
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listDevices: {items: page, nextToken}}} = await API.graphql({
                    query: query,
                    variables: {nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
            setDevices(res);
        } catch (err) {
            console.error(err);
        }
    }

    const getFilteredObjects = (options) => {
        let filter = null;
        if (options.data != null) {
            filter = [["customerID", "=", options.data.customerID]];
        }
        return {
            store: objects,
            filter: filter
        };
    }

    // Fetches all Room and Zone Objects
    const fetchObjects = async () => {
        try {
            const filter = {or: [ {type: {eq: 'ROOM'}}, {type: {eq: 'ZONE'}}]}
            let res = [];
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listObjects: {items: page, nextToken}}} = await API.graphql({
                    query: listObjectsMinimal,
                    variables: {nextToken: nt,filter: filter},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                res = res.concat(page);
            } while (nt !== null)
            setObjects(res);
        } catch (err) {
            console.error(err);
        }
    }

    const editDevice = async (event) => {
        const key = {id: event.key};
        let newData = event?.newData;
        // get latest data from DDB
        const {data: {getDevices: oldData}} = await API.graphql({
            query: getDevices,
            variables: key,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        let oldCarrier = oldData.carrier;
        let newCarrier = newData.carrier;
        if (newData?.sensor3gIndex) {
            const currentDate = new Date().toISOString();
            const serialNr = oldData?.sensor3gIndex?.serialNr;
            const status = newData.sensor3gIndex?.status;
            const sensor3gIndexInput = {
                serialNr,
                status,
                measImportStartDateTime: status === "ACTIVE" ? currentDate : null,
                measImportEndDateTime: status === "STOPPED" ? currentDate : null,
            };
            delete newData.sensor3gIndex;
            API.graphql({
                query: updateSensor3gIndex,
                variables: {input: sensor3gIndexInput},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            }).then(() => {
                notify("Sensorstatus erfolgreich aktualisiert", "success", 3000);
            }).catch((e) => {
                alert(JSON.stringify(e));
                event.cancel = true;
            });
        }
        let deviceInput = {...key, ...newData};

        // if carrier changed, set offboard from old carrier
        if (newCarrier && oldCarrier !== newCarrier) {
            if (oldCarrier === 'IOT_CORE') {
                deviceInput.onboard_iotcore = oldData.onboard_iotcore === 'ON_BOARDED' ? 'OFF_BOARDING' : oldData.onboard_iotcore;
            } else if (oldCarrier === 'DREI') {
                deviceInput.onboard_drei = oldData.onboard_drei === 'ON_BOARDED' ? 'OFF_BOARDING' : oldData.onboard_drei;
            }
        }
        //console.log(deviceInput);
        // run update (and offboarding from old)
        return API.graphql({
            query: updateDevices,
            variables: {input: deviceInput},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(async () => {
            //console.log(new Date(), oldCarrier, newCarrier);
            // if carrier changed, onboard on new carrier
            if (newCarrier && oldCarrier !== newCarrier) {
                // wait for the update in DDB
                const observedAttribute = oldData.carrier === 'IOT_CORE' ? "onboard_iotcore" : "onboard_drei";
                //console.log(new Date(), observedAttribute, "Starting polling");
                const { promise, stopPolling } = startPolling(() => API.graphql({
                    query: getDevices,
                    variables: key,
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }), ({data: {getDevices: result}}) => {
                    //console.log(result[observedAttribute], result);
                    if (result[observedAttribute] === "NO_ACTION") {
                        return new Promise((resolve) => resolve(result))
                    } else if (result[observedAttribute] === "ERROR") {
                        return new Promise((_, reject) => reject("Error during off boarding"))
                    }
                }, 1000);
                promise.then(() => {
                    let devInput = {...key, ...newData};
                    if (newCarrier === 'IOT_CORE') {
                        devInput.onboard_iotcore = newData.onboard_iotcore !== 'ON_BOARDED' ? 'ON_BOARDING' : newData.onboard_iotcore;
                    } else if (newCarrier === 'DREI') {
                        devInput.onboard_drei = newData.onboard_drei !== 'ON_BOARDED' ? 'ON_BOARDING' : newData.onboard_drei
                    }
                    //console.log(new Date(), "newData.carrier", newData.carrier, devInput);
                    return API.graphql({
                        query: updateDevices,
                        variables: {input: devInput},
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }).then(() => {
                        notify("Editieren erfolgreich", "success", 3000);
                    }).catch((e) => {
                        alert(JSON.stringify(e));
                        console.error(e);
                    });
                    }
                ).catch((msg) => {
                    alert(JSON.stringify(msg));
                    console.error(msg);
                });
                await timeout(6000);
                //console.log(new Date(), "Canceling polling");
                stopPolling();
            } else {
                notify("Editieren erfolgreich", "success", 3000);
            }
        }).catch((e) => {
            alert(JSON.stringify(e));
            console.error(e);
            event.cancel = true;
        });
    }

    // removes the device
    const removeDevice = (event) => {
        const params = {id: event.key};
        API.graphql({
            query: deleteDevices,
            variables: {input: params},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then((e) => {
            notify("Löschen erfolgreich", "success", 3000);
        }).catch((e) => {
            alert(JSON.stringify(e));
            event.cancel = true;
        });
    }

    const onEditingStart = (e) => {
        const edit3gSensor = e?.data?.is3gSensor === true;
        e.component.columnOption("sensor3gIndex.status", "formItem", {visible: edit3gSensor});
        e.component.columnOption("appeui", "formItem", {visible: !edit3gSensor});
        e.component.columnOption("appeui", "allowEditing", !edit3gSensor);
        e.component.columnOption("appkey", "formItem", {visible: !edit3gSensor});
        e.component.columnOption("appkey", "allowEditing", !edit3gSensor);
        e.component.columnOption("carrier", "formItem", {visible: !edit3gSensor});
        e.component.columnOption("carrier", "allowEditing", !edit3gSensor && authContext?.accesslevel === 1);
    }

    useEffect(() => {
        fetchObjects();
        fetchDevices(authContext?.accesslevel === 1 || authContext?.accesslevel === 2 ? listDevicesForAdmins : listDevicesForNotAdmins);
    }, []);

    if (authContext?.authState === AuthState.SignedIn) {
        return (
            <div className="DevicesList">
                <Header />
                <div className={"Content full-width"}>
                    <h2 className={"headline"}>{t("deviceslist.headline")}</h2>
                    <DataGrid
                        dataSource={devices}
                        keyExpr="id"
                        showBorders={false}
                        defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        rowAlternationEnabled={true}
                        allowColumnReordering={true}
                        wordWrapEnabled={true}
                        repaintChangesOnly={true}
                        onRowUpdating={e => editDevice(e)}
                        onRowRemoving={e => removeDevice(e)}
                        onEditingStart={e => onEditingStart(e)}
                    >
                        <Toolbar>
                            {(authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                                <TItem
                                    name="addRowButton"
                                    location={"after"}
                                    locateInMenu={"auto"}
                                    onClick={() => navigate('/sensors-register')}
                                />
                                : null
                            }
                            <TItem
                                name="columnChooserButton"
                                locateInMenu="auto"
                                location="after"
                            />
                            <TItem name="searchPanel"  location={"after"} locateInMenu={"auto"}/>
                        </Toolbar>
                        {(authContext?.accesslevel === 1 || authContext?.accesslevel === 2) ?
                            <Editing
                                mode={"popup"}
                                useIcons={true}
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                            >
                                <Popup title={t("deviceslist.popup-title")} showTitle={true} height={"auto"} maxWidth={"1000px"} maxHeight={"100vh"}>
                                <Form colCount={2}>
                                        <GroupItem caption={t("global.information")} colSpan={1}>
                                            <Item dataField="name"><Label text={t("global.name")}/></Item>
                                            <Item dataField="deveui"><Label
                                                text={t("global.deveui")}/></Item>
                                            <Item dataField="appeui"><Label text={t("global.appeui")}/></Item>
                                            <Item dataField="appkey"><Label text={t("global.appkey")}/></Item>
                                        </GroupItem>
                                    <GroupItem caption={t("global.location")} colSpan={1}>
                                        <Item dataField="objectID"><Label text={t("global.relation")}/></Item>
                                        <Item dataField="longitude"><NumericRule/><Label text={t("global.longitude")}/></Item>
                                        <Item dataField="latitude"><NumericRule/><Label text={t("global.latitude")}/></Item>
                                        <Item dataField="altitude"><NumericRule/><Label text={t("global.altitude")}/></Item>
                                    </GroupItem>
                                </Form>
                                </Popup>
                            </Editing> : null}
                        <ColumnChooser enabled={true} mode="select"/>
                        <StateStoring
                            enabled={true}
                            type="localStorage"
                            storageKey="deviceslist"
                        />
                        <LoadPanel enabled/>
                        <FilterRow/>
                        <SearchPanel visible={true} width={"auto"}/>
                        <Scrolling
                            mode="standard" />
                        <Paging
                            enabled={true}
                            defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 25, 50, 'all']}
                            showInfo={true}/>
                        <FilterRow visible={true}/>
                        {(authContext?.accesslevel === 2) ?
                            <Column dataField="customerID" groupIndex={0}
                                    sortOrder="asc" allowEditing={false} caption={t("global.customer-name")}>
                                <Lookup dataSource={customersStore} valueExpr="id" displayExpr="name" searchEnabled={true}/>
                            </Column> : null}
                        {(authContext?.accesslevel === 1) ?
                            <Column dataField="customerID" groupIndex={0}
                                    sortOrder="asc" allowEditing={false} caption={t("global.bmonc_customer_name")}>
                                <Lookup dataSource={customersStore} valueExpr="id" displayExpr="bmonc_customer_name" searchEnabled={true}/>
                            </Column> : null}
                        <Column dataField="name" dataType="string" caption={t("global.name")}/>
                        <Column dataField="deveui" dataType="string" caption={t("global.deveuiSerialNr")}/>
                        <Column dataField="objectID" dataType="string" caption={t("global.relation")}>
                            <Lookup dataSource={getFilteredObjects} valueExpr="id" displayExpr="name"/>
                        </Column>
                        <Column dataField="longitude" dataType="string" caption={t("global.longitude")} visible={false}/>
                        <Column dataField="latitude" dataType="string" caption={t("global.latitude")} visible={false}/>
                        <Column dataField="altitude" dataType="string" caption={t("global.altitude")} visible={false}/>
                        <Column dataField="description" dataType="string" caption={t("global.description")}/>
                        <Column dataField="appeui" dataType="string" caption={t("global.appeui")} visible={true} />
                        <Column dataField="appkey" dataType="string" caption={t("global.appkey")} visible={true} />
                        <Column dataField="is3gSensor" dataType="boolean" visible={false} formItem={{visible: false}} allowEditing={false}/>
                        <Column dataField="carrier"
                                dataType="string"
                                caption={t("global.carrier")}
                                visible={true}
                        >
                            <Lookup dataSource={t('carrierOptions', {returnObjects: true})} valueExpr="value" displayExpr="name" />
                        </Column>
                        <Column
                            dataField="sensor3gIndex.status"
                            dataType="string"
                            caption={t("global.sensor3gIndexStatus")}
                            visible={false}
                            editCellRender={sensor3gIndexStatusEditorRender}
                        />
                        <Column type="buttons" alignment={"right"}>
                            {(authContext?.accesslevel === 1) ?
                                <Button
                                    text="Tausch"
                                    icon="refresh"
                                    hint="Tausch"
                                    onClick={(e) => navigate(`/sensors/3g-sensors-swap/${e.row.data.id}`)}
                                    visible={(e) => e.row.data.is3gSensor === true}
                                /> : null
                            }
                            <Button name="edit" />
                            <Button name="delete" />
                        </Column>
                    </DataGrid>
                </div>
            </div>
        );
    } else {
        return(<ErrorPage/>);
    }
}

export default DevicesList;