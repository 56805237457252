export const getUserWithCustomer = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      email
      customerID
      customer {
        id
        name
        bmonc_id
        bmonc_customer_name
        atu
        iban
        bic
        address
        zipcode
        country
        phone
        email
        website
        type
        level
        tb_tenantID
        tb_ownerID
        tb_parent_customerID
        tb_customerID
        parent_customerID
        createdAt
        updatedAt
        groupRead
        groupWrite
        owner
      }
      address
      zipcode
      country
      phone
      newsletter
      agb_dsgvo
      dashboard_template_id
      status
      role
      userpool_id
      username
      tb_userID
      createdAt
      updatedAt
      ttl
      owner
      groupRead
      groupWrite
    }
  }
`;

export const listDevicesForNotAdmins = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceTypeID
        objectID
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        foto {
          bucket
          region
          key
        }
        tag
        is3gSensor
        swapProcessStatus
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listDevicesForAdmins = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceTypeID
        objectID
        name
        serialNr
        deveui
        appeui
        appkey
        latitude
        longitude
        altitude
        description
        foto {
          bucket
          region
          key
        }
        tag
        is3gSensor
        swapProcessStatus
        carrier
        onboard_iotcore
        onboard_drei
        onboard_thingsboard
        createdAt
        updatedAt
        creator
        customerID
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listObjectsMinimal = /* GraphQL */ `
  query ListObjects(
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        description
        street
        zipcode
        city
        country
        latitude
        longitude
        altitude
        mount_description
        foto {
          bucket
          region
          key
        }
        background
        tag
        parentID
        creator
        customerID
        groupRead
        groupWrite
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listLoRaWANGatewaysMinimal = /* GraphQL */ `
  query ListLoRaWANGateways(
    $filter: ModelLoRaWANGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoRaWANGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        producer
        website
        backhaulSimCard
        foto {
          bucket
          region
          key
        }
        eui
        comments
        latitude
        longitude
        altitude
        country
        customerID
        customer {
          name
          bmonc_id
          bmonc_customer_name
        }
        objectID
        wirelessGatewayId
        connstate {
          state
          code
        }
        createdAt
        updatedAt
        tag
        groupRead
        groupWrite
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;